import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { notificationController } from '@app/controllers/notificationController';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { readTeamMemberInvitation } from '@app/api/teamMemberInvitation.api';

export const VerifyInvitation: React.FC = () => {
  const [searchParams] = useSearchParams();
  const invitationId = searchParams.get('id');
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const checkInvitation = async () => {
      try {
        setLoading(true);
        const result = await readTeamMemberInvitation(invitationId ?? '');
        if (result) {
          navigate(`/auth/secure-sign-up?invitationId=${invitationId}`);
          return;
        }
        notificationController.error({ message: 'Diese Einladung ist nicht mehr gültig' });
      } catch (error: any) {
        notificationController.error({ message: error.message });
      } finally {
        setLoading(false);
      }
    };
    !isLoading && checkInvitation();
  }, []);

  return (
    <Auth.FormWrapper>
      <NormalText size="xxl">Bitte warten, wir prüfen Ihre Einladung ... </NormalText>
    </Auth.FormWrapper>
  );
};
