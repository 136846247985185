import { DownOutlined } from '@ant-design/icons';
import { media } from '@app/styles/themes/constants';
import styled from 'styled-components';
import { HeaderActionWrapper } from '../../../Header.styles';
import { Col } from 'antd';

export const ProfileDropdownHeader = styled(HeaderActionWrapper)`
  cursor: pointer;

  @media only screen and ${media.md} {
    border-radius: 50px;
    padding: 0.1rem 0.1rem;
  }
`;

export const NameContainer = styled(Col)`
  align-items: center;
  flex-wrap: wrap;
`;

export const UserName = styled.p`
  color: var(--text-main-color);
  font-weight: bold;
  line-height: 1;
  margin-top: 2px;
  margin-bottom: 6px;
  font-size: 1rem;
`;

export const CompanyName = styled.p`
  color: var(--text-main-color);
  font-size: 0.75rem;
  line-height: 1;
`;

export const DownArrow = styled(DownOutlined)`
  color: var(--text-main-color);

  @media only screen and ${media.md} {
    color: var(--text-main-color);
  }
`;
