import { KnowledgeAreaVisibilityType, KnowledgeAreaModel } from '@app/domain/KnowledgeAreaModel';
import { httpApi } from '@app/api/http.api';
import { getErrorText } from '@app/utils/apiHelpers';
import { setGlobalSessionExpired } from '@app/services/reduxStorage.service';
import { IUserAccessRight } from '@app/domain/AccessRightsModel';

export const createKnowledgeArea = (
  knowledgeAreaData: KnowledgeAreaModel,
  visibilityDepartmentIds: string[],
  specificUserRights: IUserAccessRight[],
  visibilityType: KnowledgeAreaVisibilityType,
): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post('/api/v1/knowledgeArea/create', {
        knowledgeAreaData,
        visibilityDepartmentIds,
        specificUserRights,
        visibilityType,
      });
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('createKnowledgeArea failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const readKnowledgeArea = (knowledgeAreaId: string): Promise<KnowledgeAreaModel> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.get(`/api/v1/knowledgeArea/read/${knowledgeAreaId}`);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('readKnowledgeArea failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const readAllKnowledgeAreaOfCompany = (companyId: string): Promise<KnowledgeAreaModel[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.get(`/api/v1/knowledgeArea/readAllOfCompany/${companyId}`);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('readKnowledgeArea failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const readAllKnowledgeAreaForUser = (
  companyId: string,
  userId: string,
  editRightsMode?: boolean,
): Promise<KnowledgeAreaModel[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.get(
        `/api/v1/knowledgeArea/readAllForUser/${companyId}/${userId}/${editRightsMode ?? false}`,
      );
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('readKnowledgeArea failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const updateKnowledgeArea = (
  knowledgeAreaId: string,
  knowledgeAreaData: Partial<KnowledgeAreaModel>,
): Promise<KnowledgeAreaModel> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post(`/api/v1/knowledgeArea/update/${knowledgeAreaId}`, knowledgeAreaData);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('updateKnowledgeArea failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const deleteKnowledgeArea = (knowledgeAreaId: string): Promise<boolean> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.delete(`/api/v1/knowledgeArea/delete/${knowledgeAreaId}`);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('deleteKnowledgeArea failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};
