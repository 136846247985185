import React from 'react';
import styled from 'styled-components';
import { Col, Spin } from 'antd';
import { NormalText } from './BaseTexts/BaseTexts';

interface LoadingSpinProps {
  marginTop?: string | number;
  text?: string;
}

export const LoadingSpin: React.FC<LoadingSpinProps> = ({ marginTop, text }) => {
  return (
    <SpinnerContainer>
      <Col>
        <Spinner marginTop={marginTop} size="large" />
      </Col>
      {text && (
        <Col>
          <NormalText size="m" verticalPadding colorType="light">
            {text}
          </NormalText>
        </Col>
      )}
    </SpinnerContainer>
  );
};

const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const Spinner = styled(Spin)<LoadingSpinProps>`
  margin-top: ${(props) => props.marginTop || 0};
`;
