import React from 'react';
import * as S from './MainSider/MainSider.styles';
import { useAppSelector } from '@app/hooks/reduxHooks';

interface SiderLogoProps {
  isSiderCollapsed: boolean;
  toggleSider: () => void;
}

export const SiderLogo: React.FC<SiderLogoProps> = ({}) => {
  const { appLogo, appName } = useAppSelector((state) => state.appLayout);

  return (
    <S.SiderLogoDiv>
      <S.SiderLogoLink to="/">
        <img src={appLogo} alt={appName} width={process.env.REACT_APP_CUSTOM_APP_LOGO_WIDTH || 140} />
      </S.SiderLogoLink>
    </S.SiderLogoDiv>
  );
};
