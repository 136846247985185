import axios, { AxiosError } from 'axios';
import { ApiError } from '@app/api/ApiError';
import { readSecurityToken, readToken, readUser, setGlobalSessionExpired } from '@app/services/reduxStorage.service';
import { UserModel } from '@app/domain/UserModel';
import { Md5 } from 'ts-md5';
import { getErrorText } from '@app/utils/apiHelpers';

export const getBackendBaseURL = () => {
  if (process.env.REACT_APP_CO_BRAIN_BACKEND_URL) return process.env.REACT_APP_CO_BRAIN_BACKEND_URL;
  const protocol = window.location.protocol;
  const hostname = window.location.hostname;
  return `${protocol}//${hostname}:${process.env.REACT_APP_CO_BRAIN_BACKEND_PORT || 8080}`;
};

export const getWorkflowEngineBaseURL = () => {
  const protocol = window.location.protocol;
  const hostname = window.location.hostname;
  return `${protocol}//${hostname}:${process.env.REACT_APP_N8N_PORT || 5700}`;
};

export const baseURL = getBackendBaseURL();

export const httpApi = axios.create({
  baseURL: getBackendBaseURL(),
});

httpApi.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${readToken()}`,
    'secure-brain-service-v1': 'cobrainreact',
  };
  return config;
});

httpApi.interceptors.response.use(undefined, (error: AxiosError) => {
  throw new ApiError<ApiErrorData>(error.response?.data?.error || error?.message, error.response?.data);
});

export interface ApiErrorData {
  error: string;
}

export const sendPostRequest = async <T, R>(endpoint: string, data: T, signal?: AbortSignal): Promise<R> => {
  try {
    const result = await httpApi.post(endpoint, data, { signal });
    if (result.data?.data || result.data?.data === null || result.data?.data === false) return result.data?.data as R;
    if (result.data?.error) throw new Error(result.data?.error);
    throw new Error(process.env.NODE_ENV === 'production' ? `API call failed` : `API call ${endpoint} failed`);
  } catch (error: unknown) {
    const errorText = getErrorText(error);
    if (errorText.includes('401')) {
      setGlobalSessionExpired();
      throw new Error('Your session has expired, please log in again');
    }
    throw error;
  }
};

export const sendGetRequest = async <R>(endpoint: string): Promise<R> => {
  try {
    const result = await httpApi.get(endpoint);
    if (result.data?.data || result.data?.data === null) return result.data?.data as R;
    if (result.data?.error) throw new Error(result.data?.error);
    throw new Error(process.env.NODE_ENV === 'production' ? `API call failed` : `API call ${endpoint} failed`);
  } catch (error: unknown) {
    const errorText = getErrorText(error);
    if (errorText.includes('401')) {
      setGlobalSessionExpired();
      throw new Error('Your session has expired, please log in again');
    }
    throw error;
  }
};

export const sendDeleteRequest = async <R>(endpoint: string): Promise<R> => {
  try {
    const result = await httpApi.delete(endpoint);
    if (result.data?.data) return result.data?.data as R;
    if (result.data?.error) throw new Error(result.data?.error);
    throw new Error(process.env.NODE_ENV === 'production' ? `API call failed` : `API call ${endpoint} failed`);
  } catch (error: unknown) {
    const errorText = getErrorText(error);
    if (errorText.includes('401')) {
      setGlobalSessionExpired();
      throw new Error('Your session has expired, please log in again');
    }
    throw error;
  }
};

// !!! NEVER change, only add !!!
const SecApiKeys = [
  '12c653d0-4db5-1255-988b-6e04aa1s4b4e',
  '9bce5aea-f62d-4c7d-ba24-f8e2420g3a7f',
  'e2ac5y86-e58e-7a38-8c6a-d74dds90eec5',
  'fb8c0c12-a7ad-4c02-8e55-a0fe17cb7998',
  'a8571f4c-875b-72b3-912d-b66b87c7a30b',
  'a61dce0b-4829-43e6-b4f9-84d09005dda6',
  '6df1b3d6-09b4-61d5-8491-8c485717a824',
  'c52837f9-c082-4a2d-b83f-5dede0334861',
  '7e720ade-77f6-2584-8a30-46f5261fa568',
  '9e19642c-a10e-458d-98c5-4789dfcb562c',
];

export type ApiTokenData = { acuid: string; apiToken: string; asin: number };

const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const generateApiToken = (currentUser: UserModel, securityToken: string, apiName: string): ApiTokenData => {
  const asin = randomIntFromInterval(0, SecApiKeys.length - 1);
  const secString = SecApiKeys[asin];
  const acuid = currentUser._id;
  return { acuid, apiToken: Md5.hashStr(`${acuid}_${securityToken}_${apiName}_${secString}`), asin };
};

export const generateFullApiTokenData = (apiName: string): ApiTokenData => {
  const currentUser = readUser();
  const securityToken = readSecurityToken();
  const apiTokenData =
    currentUser && securityToken
      ? generateApiToken(currentUser, securityToken, apiName)
      : { acuid: '', apiToken: '', asin: 0 };
  return apiTokenData;
};
