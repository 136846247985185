import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UploadedDocumentState {
  currentSelectedFileUrl: string | null;
  currentSelectedFileName: string | null;
  currentSelectedTemplateFileName: string | null;
  addedOrRemovedAnalysisFile: boolean;
}

const initialState: UploadedDocumentState = {
  currentSelectedFileUrl: null,
  currentSelectedFileName: null,
  currentSelectedTemplateFileName: null,
  addedOrRemovedAnalysisFile: false,
};

export const uploadedDocumentSlice = createSlice({
  name: 'uploadedDocument',
  initialState,
  reducers: {
    setCurrentSelectedFileUrl: (state, { payload }: PayloadAction<{ value: string }>) => {
      state.currentSelectedFileUrl = payload.value;
    },
    setCurrentSelectedTemplateFileName: (state, { payload }: PayloadAction<{ value: string }>) => {
      state.currentSelectedTemplateFileName = payload.value;
    },
    setCurrentSelectedFileName: (state, { payload }: PayloadAction<{ value: string }>) => {
      state.currentSelectedFileName = payload.value;
    },
    setAddedOrRemovedAnalysisFile: (state, { payload }: PayloadAction<{ value: boolean }>) => {
      state.addedOrRemovedAnalysisFile = payload.value;
    },
  },
});

export const {
  setCurrentSelectedFileUrl,
  setCurrentSelectedFileName,
  setCurrentSelectedTemplateFileName,
  setAddedOrRemovedAnalysisFile,
} = uploadedDocumentSlice.actions;

export default uploadedDocumentSlice.reducer;
