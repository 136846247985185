import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../store';
import { IDepartment } from '@app/domain/DepartmentModel';
import { readAllDepartmentsOfCompany } from '@app/api/department.api';

type DepartmentStateModel = { departments: IDepartment[] };

const initialState: DepartmentStateModel = {
  departments: [],
};

export const departmentSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    setDepartments: (state, { payload }: PayloadAction<{ value: IDepartment[] }>) => {
      state.departments = payload.value;
    },
  },
});

export const loadDepartments = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const result = await readAllDepartmentsOfCompany(getState().user.user?.belongsToCompanyId ?? '-');
    if (result) {
      dispatch(setDepartments({ value: result }));
    }
  } catch (error) {
    console.log('****** loadDepartments error: ', error);
  }
};

export const { setDepartments } = departmentSlice.actions;

export default departmentSlice.reducer;
