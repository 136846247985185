import { combineReducers } from '@reduxjs/toolkit';
import userReducer from '@app/store/slices/userSlice';
import legalDraftReducer from '@app/store/slices/legalDraftSlice';
import companyReducer from '@app/store/slices/companySlice';
import uploadedDocumentsReducer from '@app/store/slices/uploadedDocumentsSlice';
import authReducer from '@app/store/slices/authSlice';
import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
import pwaReducer from '@app/store/slices/pwaSlice';
import caveatReducer from '@app/store/slices/caveatSlice';
import techOfferReducer from '@app/store/slices/techOfferSlice';
import departmentReducer from '@app/store/slices/departmentSlice';
import settingsReducer from '@app/store/slices/settingsSlice';
import chatBoxReducer from '@app/store/slices/chatBoxSlice';
import knowledgeAreaReducer from '@app/store/slices/knowledgeAreaSlice';
import superDoReducer from '@app/store/slices/superDoSlice';
import appLayoutReducer from '@app/store/slices/appLayoutSlice';

const rootReducer = combineReducers({
  user: userReducer,
  auth: authReducer,
  nightMode: nightModeReducer,
  theme: themeReducer,
  pwa: pwaReducer,
  legalDraft: legalDraftReducer,
  company: companyReducer,
  uploadedDocuments: uploadedDocumentsReducer,
  caveat: caveatReducer,
  techOffer: techOfferReducer,
  department: departmentReducer,
  settings: settingsReducer,
  chatBox: chatBoxReducer,
  knowledgeArea: knowledgeAreaReducer,
  superDos: superDoReducer,
  appLayout: appLayoutReducer,
});

export default rootReducer;
