import { TeamMemberInvitationModel } from '@app/domain/TeamMemberInvitationModel';
import { httpApi } from '@app/api/http.api';
import { getErrorText } from '@app/utils/apiHelpers';
import { setGlobalSessionExpired } from '@app/services/reduxStorage.service';

export const createTeamMemberInvitation = (invitationData: TeamMemberInvitationModel): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post('/api/v1/teammemberinvitation/create', invitationData);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('createTeamMemberInvitation failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const readTeamMemberInvitation = (invitationId: string): Promise<TeamMemberInvitationModel> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.get(`/api/v1/teammemberinvitation/read/${invitationId}`);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('readTeamMemberInvitation failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const readAllTeamMemberInvitationForCompany = (companyId: string): Promise<TeamMemberInvitationModel[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.get(`/api/v1/teammemberinvitation/readAll/${companyId}`);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('readTeamMemberInvitation failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const updateTeamMemberInvitation = (
  invitationId: string,
  invitationData: Partial<TeamMemberInvitationModel>,
): Promise<TeamMemberInvitationModel> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post(`/api/v1/teammemberinvitation/update/${invitationId}`, invitationData);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('updateTeamMemberInvitation failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const deleteTeamMemberInvitation = (invitationId: string): Promise<boolean> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.delete(`/api/v1/teammemberinvitation/delete/${invitationId}`);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('deleteTeamMemberInvitation failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};
