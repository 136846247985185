import { CompanyModel } from '@app/domain/CompanyModel';
import { generateApiToken, httpApi } from '@app/api/http.api';
import { UserModel } from '@app/domain/UserModel';
import { readSecurityToken, readUser, setGlobalSessionExpired } from '@app/services/reduxStorage.service';
import { getErrorText } from '@app/utils/apiHelpers';

export const createCompany = (companyData: CompanyModel): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post('/api/v1/company/create', companyData);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('createCompany failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const readCompany = (companyId: string): Promise<CompanyModel> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.get(`/api/v1/company/read/${companyId}`);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('readCompany failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const getCompanyTeamMembers = (companyId: string): Promise<UserModel[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.get(`/api/v1/company/teamMembers/${companyId}`);
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('readCompany failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const getUsersBelongingToDepartment = (companyId: string, departmentIds: string[]): Promise<UserModel[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post(`/api/v1/company/teamMembers/belongingToDepartment`, {
        companyId,
        departmentIds,
      });
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('readCompany failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const getUsersByIds = (userIds: string[]): Promise<UserModel[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post(`/api/v1/company/teamMembers/byUserIds`, { userIds });
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('readCompany failed');
    } catch (error: unknown) {
      const errorText = getErrorText(error);
      if (errorText.includes('401')) {
        setGlobalSessionExpired();
        reject(new Error('Your session has expired, please log in again'));
        return;
      }
      reject(error);
    }
  });
};

export const updateCompany = (companyId: string, updateData: Partial<CompanyModel>): Promise<CompanyModel> => {
  return new Promise(async (resolve, reject) => {
    try {
      const currentUser = readUser();
      const securityToken = readSecurityToken();
      const apiTokens = currentUser && securityToken ? generateApiToken(currentUser, securityToken, '/update') : {};
      const result = await httpApi.post(`/api/v1/company/update/${companyId}`, { updateData, ...apiTokens });
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('updateCompany failed');
    } catch (error: unknown) {
      if (error instanceof Error) {
        reject(error.message);
      } else {
        reject(error);
      }
    }
  });
};

export const uploadGlobalAppLogo = (formData: FormData): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post('/api/v1/company/uploadGlobalAppLogo', formData);
      if (result.data?.data) return resolve(result.data.data);
      if (result.data?.error) return reject(result.data.error);
      reject('updateUser failed');
    } catch (error: unknown) {
      if (error instanceof Error) {
        reject(error.message);
      } else {
        reject(error);
      }
    }
  });
};
