import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { WithChildrenProps } from '@app/types/generalTypes';
import { doVerifyAuth } from '@app/store/slices/authSlice';
import { loadCompany } from '@app/store/slices/companySlice';
import { loadDepartments } from '@app/store/slices/departmentSlice';
import { LoadingSpin } from '../common/LoadingSpin';
import { loadKnowledgeAreas } from '@app/store/slices/knowledgeAreaSlice';
import { useTranslation } from 'react-i18next';
import { checkVersionIsOk } from '@app/api/general.api';
import { Button, Modal, Space } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { loadChatHistory } from '@app/store/slices/chatBoxSlice';
import { loadUserRights } from '@app/store/slices/userSlice';

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [userExists, setUserExists] = useState(false);
  const [loading, setLoading] = useState(true);

  const { user, isAdmin, sessionExpired } = useAppSelector((state) => state.user);
  const companyId = useAppSelector((state) => state.company._id);

  useEffect(() => {
    dispatch(doVerifyAuth())
      .unwrap()
      .then(async (res) => {
        setUserExists(res);
        if (res) {
          await dispatch(loadCompany());
          await dispatch(loadDepartments());
          await dispatch(loadKnowledgeAreas());
          await dispatch(loadChatHistory());
          await dispatch(loadUserRights());
        }
      })
      .finally(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    const checkClientVersion = async () => {
      const result = await checkVersionIsOk();
      if (!result.versionOk) {
        Modal.confirm({
          title: t('yourAppVersionIsOld'),
          icon: <InfoCircleOutlined />,
          content: t('yourAppVersionIsOldDescription'),
          okText: t('ok'),
        });
      }
    };
    if (!loading) {
      checkClientVersion();
    }
  }, [loading]);

  if (loading) return <LoadingSpin marginTop={'5rem'} text={t('establishingSecureConnection')} />;
  if (user?._id && !loading && !isAdmin && !companyId)
    return (
      <NormalText size="m" centered verticalPadding colorType="light">
        {t('wrongConfigurationFound')}
      </NormalText>
    );

  return userExists ? (
    <>
      {children}
      <Modal
        title={t('yourSessionExpired')}
        closable={false}
        visible={sessionExpired}
        footer={[
          <Button key="submit" type="primary" onClick={() => navigate('/logout')}>
            Ok
          </Button>,
        ]}
      >
        <Space size={'middle'} align="start">
          <InfoCircleOutlined />
          <NormalText size="m" style={{ marginBottom: '1rem' }}>
            {t('yourSessionExpiredHint')}
          </NormalText>
        </Space>
      </Modal>
    </>
  ) : (
    <Navigate to="/auth/login" replace />
  );
};

export default RequireAuth;
