import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import { httpApi } from '@app/api/http.api';
import { notificationController } from '@app/controllers/notificationController';

export const VerifyEmailCode: React.FC = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const checkCode = async () => {
      try {
        setLoading(true);
        const result = await httpApi.post('/api/v1/user/auth/verifyEmail', { code });
        if (result.data?.data?.verified) {
          navigate('/');
          return;
        }
        navigate('/auth/login');
      } catch (error: any) {
        notificationController.error({ message: error.message });
      } finally {
        setLoading(false);
      }
    };
    !isLoading && checkCode();
  }, []);

  return <Auth.FormWrapper></Auth.FormWrapper>;
};
