import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import { LeftCircleFilled } from '@ant-design/icons';

export const MenuWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const HeaderContainer = styled.div`
  padding: 12px;
  width: '100%';
`;

export const HeaderTitle = styled(Typography.Text)`
  color: var(--primary-color);
  font-size: 1.125rem;
`;

export const BackIcon = styled(LeftCircleFilled)`
  color: var(--primary-color);
  width: '60px';
  height: '60px';
  font-size: 2rem;
`;

export const StyledLogoutLink = styled(Link)`
  margin: 1rem;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: 1px solid var(--lightgrey);
  border-radius: 6px;
  color: var(--text-sider-secondary-color);
`;
