import React, { useRef, useState } from 'react';
import { Modal, Form, Row, Col, Button, Radio, Space, message, Divider } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { createKnowledgeArea } from '@app/api/knowledgeArea.api';
import { KnowledgeAreaVisibilityType, KnowledgeAreaModel } from '@app/domain/KnowledgeAreaModel';
import moment from 'moment';
import { setLoadedKnowledgeAreas } from '@app/store/slices/knowledgeAreaSlice';
import { BaseInput } from '../common/inputs/BaseInput/BaseInput';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { BaseSelect } from '../common/selects/BaseSelect/BaseSelect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AccessRightTeamMember } from './OverviewAccessRights';
import SearchUsersAccessRights from './SearchUsersAccessRights';
import { UserRightsContainer } from './ModalCreateNewDataroom.styles';
import { IUserAccessRight } from '@app/domain/AccessRightsModel';

type ModalCreateNewDataroomProps = {
  showCreateNewDataRoomModal: boolean;
  onCloseModal: (createdKnowledeItemId?: string) => void;
};

const MaxKnowledgeAreas = Number.parseInt(process.env.REACT_APP_MAX_KNOWLEDGE_AREAS ?? '') || 10;

export const ModalCreateNewDataroom: React.FC<ModalCreateNewDataroomProps> = ({
  showCreateNewDataRoomModal,
  onCloseModal,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const selectRef = useRef(null);

  const [form] = Form.useForm();
  const [step, setStep] = useState(1);
  const [visibility, setVisibility] = useState<KnowledgeAreaVisibilityType>('OPEN_FOR_WHOLE_COMPANY');
  const [title, setTitle] = useState('');
  const [accessRightTeamMembers, setAccessRightTeamMembers] = useState<AccessRightTeamMember[]>([]);

  const companyState = useAppSelector((state) => state.company);
  const user = useAppSelector((state) => state.user.user);
  const { loadedKnowledgeAreas } = useAppSelector((state) => state.knowledgeArea);
  const departments = useAppSelector((state) => state.department.departments);

  const dispatch = useAppDispatch();

  const maxLimitExceed = loadedKnowledgeAreas.length > MaxKnowledgeAreas;

  const handleOkCreateNew = () => {
    form
      .validateFields()
      .then(async (values) => {
        const thematicAreaId = generateThematicAreaId(values.title);
        try {
          let visibilityDepartmentIds: string[] = [];
          const specificUserRights: IUserAccessRight[] = [];
          if (visibility === 'ONLY_SPECIFIC_USERS') {
            for (const right of accessRightTeamMembers) {
              specificUserRights.push({ userId: right.userId, accessRight: right.accessRight });
            }
          } else if (visibility === 'ONLY_SPECIFIC_DEPARTMENTS') {
            visibilityDepartmentIds = values.departmentIds;
          }
          const knowledgeItem: KnowledgeAreaModel = {
            title: values.title,
            description: values.description,
            thematicAreaId: thematicAreaId,
            createdByUserId: user?._id ?? '',
            companyId: companyState._id ?? '',
            timestampLastUpdatedAt: moment().unix(),
          };
          const id = await createKnowledgeArea(knowledgeItem, visibilityDepartmentIds, specificUserRights, visibility);
          knowledgeItem._id = id;
          dispatch(setLoadedKnowledgeAreas({ value: [...loadedKnowledgeAreas, knowledgeItem] }));
          form.resetFields();
          onCloseModal(id);
          setStep(1);
          message.success(t('knowledgeAreaCreatedSuccessfully', { title: values.title }));
        } catch (error) {
          console.log('****** handleOkCreateNew error: ', error);
          if (error instanceof Error) {
            notificationController.error({ message: error.message });
          } else if (typeof error === 'string') {
            notificationController.error({ message: error });
          } else {
            notificationController.error({
              message: t('knowledgeAreaCreationError'),
            });
          }
        }
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const onNextStep = () => {
    if (step === 2) {
      handleOkCreateNew();
    } else {
      form.validateFields().then(() => setStep(step + 1));
    }
  };

  const handleCancel = () => {
    onCloseModal();
    form.resetFields();
    setStep(1);
  };

  const generateThematicAreaId = (input: string): string => {
    let formatted = input.toLowerCase();
    formatted = formatted.replace(/ä/g, 'a').replace(/ü/g, 'u').replace(/ö/g, 'o').replace(/ß/g, 'ss');
    formatted = formatted.replace(/\s+/g, '-');

    // Entferne alle Nicht-ASCII-Zeichen
    formatted = formatted.replace(/[^\x00-\x7F]/g, '');
    return formatted;
  };

  if (maxLimitExceed) {
    return (
      <Modal
        title={t('createNewKnowledgeArea')}
        open={showCreateNewDataRoomModal}
        onCancel={handleCancel}
        onOk={handleCancel}
      >
        <NormalText size="m" style={{ marginBottom: '1rem' }}>
          {t('maxKnowledgeAreasReached', { count: MaxKnowledgeAreas })}
        </NormalText>
      </Modal>
    );
  }

  if (!companyState._id) {
    return (
      <Modal
        title={t('createNewKnowledgeArea')}
        open={showCreateNewDataRoomModal}
        onCancel={handleCancel}
        onOk={handleCancel}
      >
        <NormalText size="m" style={{ marginBottom: '1rem' }}>
          {t('pleaseCreateCompanyFirst')}
        </NormalText>
        <Button
          onClick={() => {
            handleCancel();
            navigate('/company-overview');
          }}
        >
          {t('hereCreateCompany')}
        </Button>
      </Modal>
    );
  }

  return (
    <Modal
      title={t('createNewKnowledgeArea')}
      open={showCreateNewDataRoomModal}
      onOk={handleOkCreateNew}
      onCancel={handleCancel}
      bodyStyle={{ maxHeight: '60vh', overflowY: 'scroll' }}
      footer={
        <Row justify={'space-between'} align={'middle'}>
          <Col>
            <NormalText semiBold>
              {t('step')} {step} / 2
            </NormalText>
          </Col>
          <Col>
            <Row gutter={4}>
              {step > 1 && <Button onClick={() => setStep(step - 1)}>{t('back')}</Button>}
              <Button type="primary" onClick={() => onNextStep()}>
                {step === 1 ? t('next') : t('save')}
              </Button>
            </Row>
          </Col>
        </Row>
      }
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="title"
          rules={[{ required: true, message: t('enterTitleMessage') }]}
          style={{ marginBottom: '0.5rem' }}
          hidden={step !== 1}
        >
          <BaseInput
            prefix="# "
            onChange={(event) => setTitle(event.target.value)}
            maxLength={50}
            showCount
            placeholder={t('knowledgeAreaTitle')}
          />
        </Form.Item>
        {step === 1 && (
          <NormalText size="s" colorType="light" style={{ marginBottom: '1rem' }}>
            {t('knowledgeAreaDescription')}
          </NormalText>
        )}
        <Form.Item name="description" hidden={step !== 1}>
          <BaseInput placeholder={t('descriptionOptional')} />
        </Form.Item>
        {step === 2 && (
          <NormalText size="m" light style={{ marginBottom: '1rem' }}>
            {t('knowledgeArea')} # {title}
          </NormalText>
        )}
        {step === 2 && (
          <NormalText verticalPadding size="m" semiBold>
            {t('visibility')}
          </NormalText>
        )}
        <Form.Item name="visibility" hidden={step !== 2}>
          <Radio.Group defaultValue={visibility} value={visibility} onChange={(e) => setVisibility(e.target.value)}>
            <Space direction="vertical">
              <Radio value="OPEN_FOR_WHOLE_COMPANY">{t('openForAll')}</Radio>
              <Radio value="ONLY_SPECIFIC_DEPARTMENTS">{t('exclusiveOnlyCertainDepartments')}</Radio>
              <Radio value="ONLY_SPECIFIC_USERS">{t('exclusiveOnlyCertainUsers')}</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        {step === 2 && visibility === 'ONLY_SPECIFIC_DEPARTMENTS' && (
          <Form.Item name="userRights" rules={[{ required: true, message: t('pleaseSelectAtLeastOneDepartment') }]}>
            <BaseSelect
              mode="multiple"
              ref={selectRef}
              allowClear
              style={{ width: '100%' }}
              placeholder={t('pleaseSelectDepartment')}
              options={departments.map((item) => ({ value: item._id, label: item.name }))}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: '8px 0' }} />
                  <div style={{ textAlign: 'end' }}>
                    <Button
                      type="link"
                      onClick={() => {
                        //@ts-expect-error ignore
                        selectRef.current?.blur();
                      }}
                    >
                      {t('confirmSelection')}
                    </Button>
                  </div>
                </>
              )}
            />
          </Form.Item>
        )}
        {step === 2 && visibility === 'ONLY_SPECIFIC_USERS' && (
          <UserRightsContainer>
            <SearchUsersAccessRights
              hideCurrentUser
              accessRightTeamMembers={accessRightTeamMembers}
              setAccessRightTeamMembers={(rights) => setAccessRightTeamMembers(rights)}
            />
          </UserRightsContainer>
        )}
      </Form>
    </Modal>
  );
};
