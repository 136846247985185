import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/de';
import '@quillforms/renderer-core/build-style/style.css';
import { useLocation } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { MemoizedReactMarkdown } from '@app/components/common/Markdown/MemoizedReactMarkdown';
import rehypeMathjax from 'rehype-mathjax';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import { Divider, Spin } from 'antd';
import { FirstStepAnswers } from './SurveyHRTypes';
import { BackLink, Block, LoadingBlock } from './AiSurveyHR.styles';
import { executeGptQuery } from '@app/api/gpt.api';

moment.locale('de');

function AiSurveyHRAutoGenerated() {
  const location = useLocation();

  const [loadingQuestions, setLoadingQuestions] = useState(true);
  const [showResults, setShowResults] = useState(false);
  const [reportText, setReportText] = useState<string>('');

  useEffect(() => {
    generateReport();
  }, []);

  const generateReport = async () => {
    try {
      setLoadingQuestions(true);
      const firstStepAnswers = location.state as FirstStepAnswers;

      const data = await executeGptQuery({
        queryText: `
      Handle als ein erfahrener Digitalisierungsberater für HR.
      Ein Unternehmen hat einen Ist-Zustand-HR Fragebogen wie folgt beantwortet:
      "Wo wird viel Zeit reingesteckt?": ${firstStepAnswers.most_time_spent}
      "Welche Prozesse wiederholen sich oft?": ${firstStepAnswers.repeated_processes}
      "Welche Tätigkeiten werden manuell erledigt?": ${firstStepAnswers.manual_steps}
      "Wo wird Papier verwendet?": ${firstStepAnswers.areas_paper_used}
      "Wonach suchen wir immer wieder?": ${firstStepAnswers.always_searching_for}
      "Wie werden die HR-Ressourcen abgerufen - gibt es unterjährige Schwankungen?": ${firstStepAnswers.hr_ressources_accessed}
      "Werden die Fähigkeiten der Mitarbeiter:innen optimal genutzt, was kann optimiert werden? Aufgabenumverteilung?": ${firstStepAnswers.skills_optimaly_used}
      "Gibt es fehlende Kompetenzen für gewisse Aufgaben?": ${firstStepAnswers.missing_skills}
      "Externe und interne Kund:innen betrachten": ${firstStepAnswers.satisfaction_employees}
      "Wie zufrieden sind Sie mit Ihren Employer Branding Maßnahmen?": ${firstStepAnswers.satisfaction_employeer_branding}
      "Bei welchen Prozessen gibt es viele Rückfragen oder Beschwerden?": ${firstStepAnswers.bad_processes}
      "Wo gibt es lange Wartezeiten?": ${firstStepAnswers.long_wating_time}
      "Wo gibt es doppelte/mehrfache manuelle Erfassungen/Eintragungen an unterschiedlichen Orten?": ${firstStepAnswers.rendundant_data}
      "Welche Vorgänge erzeugen keinen Mehrwert für den:die Kund:in?": ${firstStepAnswers.no_added_value}
      "Wie werden Mitarbeiter_innen über arbeitsrechtliche, tarifrechtliche oder andere Vorgaben und Regelungen informiert?": ${firstStepAnswers.employee_informations}
      "Wo passieren häufige Fehler, die zu Nacharbeiten führen?": ${firstStepAnswers.issued_steps}
      "In welchen Bereichen müssen rechtliche Standards und Vorgaben eingehalten werden?": ${firstStepAnswers.regulations}
      "Welche Tätigkeiten werden im Team häufig im Kreis herum gereicht?": ${firstStepAnswers.responsibility_circle_around}
      "Wo funktioniert die übergreifende Zusammenarbeit nicht gut?": ${firstStepAnswers.bad_cooperation}
      "Gibt es unterschiedliche Standorte, die zusammenarbeiten?": ${firstStepAnswers.different_locations}

      Erstelle auf Basis der Antworten aus dem Fragebogen eine Empfehlung für eine Roadmap mit Priorisierung was als erstes angegangen werden soll. Der Report soll max. 4000 Zeichen lang sein.
      Die Empfehlung soll wirklich Mehrwert liefern und ins Detail gehen. Begründe deine Ergebnisse.
      Gib die Empfehlung schön formatiert in Markdown zurück.`,
      });

      const reportText: string = data;
      setReportText(reportText);
      setShowResults(true);
    } catch (error) {
      notificationController.error({
        message: 'Das hat leider nicht geklappt. Bitte wiederhole die Anfrage erneut.',
      });
    } finally {
      setLoadingQuestions(false);
    }
  };

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      {loadingQuestions ? (
        <LoadingBlock>
          <Spin tip="Ihre Daten werden von der KI verarbeitet ..." size="large">
            <div className="content" />
          </Spin>
        </LoadingBlock>
      ) : (
        <>
          {showResults && (
            <Block>
              <BackLink to="/survey">{'Zurück'}</BackLink>
              <Divider />
              <MemoizedReactMarkdown
                className="prose flex-1"
                remarkPlugins={[remarkGfm, remarkMath]}
                rehypePlugins={[rehypeMathjax]}
              >
                {`${reportText}`}
              </MemoizedReactMarkdown>
            </Block>
          )}
        </>
      )}
    </div>
  );
}

export default AiSurveyHRAutoGenerated;
