import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { SignUpForm } from '@app/components/auth/SignUpForm/SignUpForm';
import { useAppSelector } from '@app/hooks/reduxHooks';

const SignUpPage: React.FC = () => {
  const { t } = useTranslation();
  const { appName } = useAppSelector((state) => state.appLayout);

  return (
    <>
      <PageTitle>{t('common.signUp', { appName })}</PageTitle>
      <SignUpForm />
    </>
  );
};

export default SignUpPage;
