import { CaveatAnalysisResult } from '@app/types/caveatTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface CaveatState {
  caveatAnalysisResults: CaveatAnalysisResult[];
  caveatCategories: string | null;
}

const initialState: CaveatState = {
  caveatAnalysisResults: [],
  caveatCategories: null,
};

export const caveatSlice = createSlice({
  name: 'caveatSlice',
  initialState,
  reducers: {
    setCaveatAnalysisResults: (state, { payload }: PayloadAction<{ value: CaveatAnalysisResult[] }>) => {
      state.caveatAnalysisResults = payload.value;
    },
    addCaveatAnalysisResults: (state, { payload }: PayloadAction<{ value: CaveatAnalysisResult }>) => {
      state.caveatAnalysisResults.push(payload.value);
    },
    setCaveatCategories: (state, { payload }: PayloadAction<{ value: string }>) => {
      state.caveatCategories = payload.value;
    },
  },
});

export const { setCaveatAnalysisResults, addCaveatAnalysisResults, setCaveatCategories } = caveatSlice.actions;

export default caveatSlice.reducer;
