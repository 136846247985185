/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import moment from 'moment';
import 'moment/locale/de';
import { Form } from '@quillforms/renderer-core';
import '@quillforms/renderer-core/build-style/style.css';
import { useNavigate } from 'react-router-dom';
import { FirstStepAnswers } from './SurveyHRTypes';
import { CustomFormMessages } from './SurveyHRData';
//@ts-ignore
import { registerCoreBlocks } from '@quillforms/react-renderer-utils';

registerCoreBlocks();
moment.locale('de');

const AiHRSurvey: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <Form
        formId={101}
        applyLogic
        formObj={{
          messages: CustomFormMessages,
          blocks: [
            {
              name: 'welcome-screen',
              id: 'jg1401r',
              attributes: {
                label: 'Willkommen zu Aijando - Ihrem KI HR Digitalisierungsberater',
                description:
                  'Erhalten Sie auf Basis von GPT4 eine individuelle Beratung für das Digialisieren Ihrer HR-Abteilung.',
                attachment: {
                  type: 'image',
                  url: 'https://quillforms.com/wp-content/uploads/2022/01/4207-ai-1.jpeg',
                },
              },
            },
            {
              name: 'long-text',
              id: 'most_time_spent',
              attributes: {
                classnames: 'first-block',
                required: true,
                label: 'Wo wird viel Zeit reingesteckt?',
                description: 'Dies bezieht sich sowohl auf Prozesse als auch auf menschliche Interaktionen.',
                setMaxCharacters: true,
                maxCharacters: 300,
              },
            },
            {
              name: 'short-text',
              id: 'repeated_processes',
              attributes: {
                required: true,
                label: 'Welche Prozesse wiederholen sich oft?',
              },
            },
            {
              name: 'short-text',
              id: 'manual_steps',
              attributes: {
                required: true,
                label: 'Welche Tätigkeiten werden manuell erledigt?',
              },
            },
            {
              name: 'short-text',
              id: 'areas_paper_used',
              attributes: {
                label: 'Wo wird Papier verwendet?',
              },
            },
            {
              name: 'short-text',
              id: 'always_searching_for',
              attributes: {
                label: 'Wonach suchen wir immer wieder?',
              },
            },
            {
              name: 'short-text',
              id: 'hr_ressources_accessed',
              attributes: {
                label: 'Wie werden die HR-Ressourcen abgerufen - gibt es unterjährige Schwankungen?',
                description: 'Z.B. Jahreswechsel, Recruiting-Stoßzeiten, etc.',
              },
            },
            {
              name: 'short-text',
              id: 'skills_optimaly_used',
              attributes: {
                label:
                  'Werden die Fähigkeiten der Mitarbeiter:innen optimal genutzt, was kann optimiert werden? Aufgabenumverteilung?',
              },
            },
            {
              name: 'short-text',
              id: 'missing_skills',
              attributes: {
                label: 'Gibt es fehlende Kompetenzen für gewisse Aufgaben?',
              },
            },
            {
              name: 'statement',
              id: 'satisfaction_between_step',
              attributes: {
                label: 'Lassen Sie uns im Detail anschauen, wie zufrieden Sie und die einzelnen Beteiligten sind',
              },
            },
            // next section
            {
              name: 'short-text',
              id: 'satisfaction_employees',
              attributes: {
                label: 'Externe und interne Kund:innen betrachten',
                description: 'Z.B. Berber:innen, Geschäftspartner:innen, Belegschaft',
              },
            },
            {
              name: 'short-text',
              id: 'satisfaction_employeer_branding',
              attributes: {
                label: 'Wie zufrieden sind Sie mit Ihren Employer Branding Maßnahmen?',
              },
            },
            {
              name: 'short-text',
              id: 'bad_processes',
              attributes: {
                label: 'Bei welchen Prozessen gibt es viele Rückfragen oder Beschwerden?',
              },
            },
            {
              name: 'short-text',
              id: 'long_wating_time',
              attributes: {
                label: 'Wo gibt es lange Wartezeiten?',
              },
            },
            {
              name: 'short-text',
              id: 'rendundant_data',
              attributes: {
                label: 'Wo gibt es doppelte/mehrfache manuelle Erfassungen/Eintragungen an unterschiedlichen Orten?',
              },
            },
            {
              name: 'short-text',
              id: 'no_added_value',
              attributes: {
                label: 'Welche Vorgänge erzeugen keinen Mehrwert für den:die Kund:in?',
              },
            },
            {
              name: 'short-text',
              id: 'employee_informations',
              attributes: {
                label:
                  'Wie werden Mitarbeiter_innen über arbeitsrechtliche, tarifrechtliche oder andere Vorgaben und Regelungen informiert?',
                description: 'Z.B. zentrale Anlaufstelle wie Intranet, HR-Chatbot oder 1:1 bei HR',
              },
            },
            {
              name: 'short-text',
              id: 'issued_steps',
              attributes: {
                label: 'Wo passieren häufige Fehler, die zu Nacharbeiten führen?',
              },
            },
            {
              name: 'short-text',
              id: 'regulations',
              attributes: {
                label: 'In welchen Bereichen müssen rechtliche Standards und Vorgaben eingehalten werden?',
                description: '(DGVGO, Dokumentationspflicht, Aufbewahrungspflicht)',
              },
            },
            {
              name: 'statement',
              id: 'responsibility_between_step',
              attributes: {
                label: 'Lassen Sie uns im Detail anschauen, wie mit Zuständigkeiten umgegangen wird',
              },
            },
            // next section
            {
              name: 'short-text',
              id: 'responsibility_circle_around',
              attributes: {
                label: 'Welche Tätigkeiten werden im Team häufig im Kreis herum gereicht?',
              },
            },
            {
              name: 'short-text',
              id: 'bad_cooperation',
              attributes: {
                label: 'Wo funktioniert die übergreifende Zusammenarbeit nicht gut?',
                description: 'Z.B. Finanzbuchhaltung, Fachabteilung, IT, etc.',
              },
            },
            {
              name: 'short-text',
              id: 'different_locations',
              attributes: {
                label: 'Gibt es unterschiedliche Standorte, die zusammenarbeiten?',
              },
            },
          ],
        }}
        onSubmit={(data, { completeForm, setIsSubmitting }) => {
          setTimeout(() => {
            setIsSubmitting(false);
            completeForm();
            const dataAny = data as any;
            if (dataAny.answers) {
              const answers: FirstStepAnswers = {
                most_time_spent: dataAny.answers['most_time_spent'].value,
                repeated_processes: dataAny.answers['repeated_processes'].value,
                manual_steps: dataAny.answers['manual_steps'].value,
                areas_paper_used: dataAny.answers['areas_paper_used'].value,
                always_searching_for: dataAny.answers['always_searching_for'].value,
                hr_ressources_accessed: dataAny.answers['hr_ressources_accessed'].value,
                skills_optimaly_used: dataAny.answers['skills_optimaly_used'].value,
                missing_skills: dataAny.answers['missing_skills'].value,
                satisfaction_employees: dataAny.answers['satisfaction_employees'].value,
                satisfaction_employeer_branding: dataAny.answers['satisfaction_employeer_branding'].value,
                bad_processes: dataAny.answers['bad_processes'].value,
                long_wating_time: dataAny.answers['long_wating_time'].value,
                rendundant_data: dataAny.answers['rendundant_data'].value,
                no_added_value: dataAny.answers['no_added_value'].value,
                employee_informations: dataAny.answers['employee_informations'].value,
                issued_steps: dataAny.answers['issued_steps'].value,
                regulations: dataAny.answers['regulations'].value,
                responsibility_circle_around: dataAny.answers['responsibility_circle_around'].value,
                bad_cooperation: dataAny.answers['bad_cooperation'].value,
                different_locations: dataAny.answers['different_locations'].value,
              };
              navigate('/survey-ai-hr-generated', { state: answers });
            }
          }, 500);
        }}
      />
    </div>
  );
};

export default AiHRSurvey;
