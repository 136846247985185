import { sendPostRequest, sendGetRequest, sendDeleteRequest } from '@app/api/http.api';
import {
  AccessRightEntityType,
  IAccessRights,
  IUserDetailedAccessRight,
  IUserDetailedAccessRights,
  IUserOverviewAccessRights,
} from '@app/domain/AccessRightsModel';

export const createAccessRight = (data: IAccessRights): Promise<string> => {
  return sendPostRequest<IAccessRights, string>('/api/v1/accessRights/create', data);
};

export const readAccessRight = (id: string): Promise<IAccessRights | null> => {
  return sendGetRequest<IAccessRights | null>(`/api/v1/accessRights/${id}`);
};

export const readAccessRightByEntity = (
  companyId: string,
  entityType: AccessRightEntityType,
  entityId: string,
): Promise<IAccessRights | null> => {
  return sendGetRequest<IAccessRights | null>(`/api/v1/accessRights/${companyId}/${entityType}/${entityId}`);
};

export const readAccessRightByEntityType = (
  companyId: string,
  entityType: AccessRightEntityType,
): Promise<IAccessRights | null> => {
  return sendGetRequest<IAccessRights | null>(`/api/v1/accessRights/byType/${companyId}/${entityType}`);
};

export const readAccessRightsByUser = (
  companyId: string,
  userId: string,
): Promise<IUserDetailedAccessRights | null> => {
  return sendGetRequest<IUserDetailedAccessRights | null>(`/api/v1/accessRights/allByUser/${companyId}/${userId}`);
};

export const readOverviewAccessRightsForUsers = (companyId: string): Promise<IUserOverviewAccessRights[] | null> => {
  return sendGetRequest<IUserOverviewAccessRights[] | null>(`/api/v1/accessRights/usersOverview/${companyId}`);
};

export const updateAccessRightForUser = (
  companyId: string,
  adminUserId: string,
  userId: string,
  updatedAccessRights: IUserDetailedAccessRight[],
): Promise<string> => {
  return sendPostRequest<
    { companyId: string; adminUserId: string; updatedAccessRights: IUserDetailedAccessRight[] },
    string
  >(`/api/v1/accessRights/updateForUser/${userId}`, {
    companyId,
    adminUserId,
    updatedAccessRights,
  });
};

export const updateAccessRight = (id: string, updatedAccessRight: IAccessRights): Promise<string> => {
  return sendPostRequest<{ updatedAccessRight: IAccessRights }, string>(`/api/v1/accessRights/${id}`, {
    updatedAccessRight,
  });
};

export const deleteAccessRight = (id: string): Promise<string> => {
  return sendDeleteRequest<string>(`/api/v1/accessRights/${id}`);
};
