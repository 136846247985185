import { translate } from '@app/i18n';

export const AreaIds = {
  HR: 'HR',
  MARKETING: 'MARKETING',
  SALES: 'SALES',
  //CUSTOMER_COMMUNICATION: 'CUSTOMER_COMMUNICATION',
  FINANCE: 'FINANCE',
  ADMINISTRATION: 'ADMINISTRATION',
  ACCOUNTING: 'ACCOUNTING',
  CUSTOM: 'CUSTOM',
};

export const AreaFilters = [
  { text: 'HR', id: AreaIds.HR },
  { text: 'Marketing', id: AreaIds.MARKETING },
  //{ text: 'Kunden-Kommunikation', id: AreaIds.CUSTOMER_COMMUNICATION },
  { text: 'Sales', id: AreaIds.SALES },
  { text: 'Finanzen', id: AreaIds.FINANCE },
  { text: 'Buchhaltung', id: AreaIds.ACCOUNTING },
  //{ text: 'Dokumente', id: AreaIds.CUSTOM },
];

type SuperDo = {
  title: string;
  description: string;
  areaId: string;
  id: number;
};

export const SuperDoTemplates: SuperDo[] = [
  // Marketing
  {
    title: 'LinkedIn Post',
    description: translate('superDo.linkedInPostDescription'),
    areaId: AreaIds.MARKETING,
    id: 26,
  },
  {
    title: 'Produktwerbetext',
    description: 'Schreiben Sie längere Artikel von Anfang bis Ende.',
    areaId: AreaIds.MARKETING,
    id: 1,
  },
  {
    title: 'Inhaltszusammenfassung',
    description: 'Fassen Sie hier den Inhalt einer Webseite zusammen',
    areaId: AreaIds.MARKETING,
    id: 2,
  },
  {
    title: 'AIDA-Framework',
    description: 'Nutzen Sie den ältesten Marketingrahmen der Welt. Aufmerksamkeit, Interesse, Verlangen, Aktion.',
    areaId: AreaIds.MARKETING,
    id: 11,
  },
  // HR
  {
    title: 'Stellenanzeige erstellen',
    description: 'Erstellen Sie eine Stellenanzeige für offene Positionen',
    areaId: AreaIds.HR,
    id: 8,
  },
  {
    title: 'Inverview-Fragen erstellen',
    description: 'Erstellen Sie Job Inverview-Fragen für neue Kandidaten',
    areaId: AreaIds.HR,
    id: 10,
  },
  {
    title: 'Personalisiertes Feedback für Bewerber',
    description: 'Erstellen Sie personalisiertes Feedback für Bewerber',
    areaId: AreaIds.HR,
    id: 12,
  },
  // // Finance
  // {
  //   title: 'Marktanalyse erstellen',
  //   description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor',
  //   areaId: AreaIds.FINANCE,
  //   id: 12,
  // },
  // {
  //   title: 'Erkennen finanzieller Trends',
  //   description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor',
  //   areaId: AreaIds.FINANCE,
  //   id: 13,
  // },
  // {
  //   title: 'Erkennen von Markttrends',
  //   description: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor',
  //   areaId: AreaIds.FINANCE,
  //   id: 14,
  // },
  // Sales
  // {
  //   title: 'Analyse des Kundenfeedbacks',
  //   description: 'Analyse des Kundenfeedbacks zu einem bestimmten Produkt',
  //   areaId: AreaIds.SALES,
  //   id: 15,
  //   //"Our company has just launched a new product, and we would like to get some feedback from our customers. Can you generate a survey that covers [specific topics] and provide us with a report on the responses we receive?"
  // },
  // {
  //   title: 'Branchentrend-Analyse',
  //   description: 'Durchführung von Branchentrend-Analysen für eine Abteilung',
  //   areaId: AreaIds.SALES,
  //   id: 16,
  //   //"Based on current data and patterns, what can we expect to see in terms of growth and innovation in [industry] over the next [time frame]? Please provide specific examples and insights."
  // },
  // {
  //   title: 'Marktforschungs-Datenanalyse',
  //   description: 'Durchführung von Marktforschungs-Datenanalysen zu einem Datenset bzw. Text',
  //   areaId: AreaIds.SALES,
  //   id: 17,
  //   //"Can you assist with analyzing the market research data for [product/service/industry], using [specific data set]? Please provide a summary of key findings, including trends, patterns, and statistical significance."
  // },
  // {
  //   title: 'Marktforschung für neue Leads',
  //   description: 'Durchführung von Marktforschung für neue Kontakte',
  //   areaId: AreaIds.SALES,
  //   id: 18,
  //   //"Can you help me identify potential customers for our [PRODUCT/SERVICE] [COMPANY] in [GEOGRAPHIC REGION]? What are the key characteristics and behaviors of our target audience in this [MARKET]?"
  // },
  // {
  //   title: 'Due-Diligence-Prüfung',
  //   description: 'Durchführung der Due-Diligence-Prüfung von Partnerschaften',
  //   areaId: AreaIds.SALES,
  //   id: 19,
  //   //"We are considering partnering with a [company name] in the [Industry/Market] space, and we need to conduct due diligence. Can you help us generate a checklist of questions to ask and information to gather about the company? We are specifically interested in learning about their products/services, financial performance, customer base, and reputation in the market."
  // },
  // {
  //   title: 'Audits der Verkaufsprozesse',
  //   description: 'Durchführung von Audits des bestehenden Verkaufsprozesses',
  //   areaId: AreaIds.SALES,
  //   id: 20,
  //   //"Can you help me conduct a sales process audit for [Sintra / our company]? We want to identify areas for improvement and optimize our sales process."
  // },
  // {
  //   title: 'Reminder-E-Mails',
  //   description: 'Verfassen von Reminder-E-Mails für Termine oder kommende Events',
  //   areaId: AreaIds.SALES,
  //   id: 21,
  //   //"Can you help me write an appointment reminder email for [company name] to remind clients of their upcoming appointment? We want to include the date and time, location, and any necessary preparation. Can you suggest a subject line and some talking points to include in the email?"
  // },
  // {
  //   title: 'Wettbewerber SWOT-Analyse',
  //   description: 'Erstellung von SWOT-Analysen der Wettbewerber',
  //   areaId: AreaIds.SALES,
  //   id: 22,
  //   //"Can you generate a SWOT analysis for [competitor] based on current data and market insights? What specific insights can we gain from this analysis?"
  // },
  // {
  //   title: 'Gründungsvertrag Einpersonengesellschaft',
  //   description: 'Erstellung eines Gründungsvertrags für eine Einpersonengesellschaft',
  //   areaId: AreaIds.ADMINISTRATION,
  //   id: 23,
  //   //"Can you generate a SWOT analysis for [competitor] based on current data and market insights? What specific insights can we gain from this analysis?"
  // },
  // {
  //   title: 'Gründungsvertrag Mehrpersonengesellschaft',
  //   description: 'Erstellung eines Gründungsvertrags für einen Mehrpersonengesellschaft',
  //   areaId: AreaIds.ADMINISTRATION,
  //   id: 24,
  //   //"Can you generate a SWOT analysis for [competitor] based on current data and market insights? What specific insights can we gain from this analysis?"
  // },
  // Kunden-Kommunikation
  {
    title: 'E-Mail Optimierung',
    description: 'Optimieren Sie Ihre E-Mails für die Kunden-Kommunikation',
    areaId: AreaIds.MARKETING,
    id: 25,
  },
  // Custom
  // {
  //   title: 'AI Document Checker',
  //   description: 'Check your contract and compliance documents with AI',
  //   areaId: AreaIds.CUSTOM,
  //   id: 26,
  // },
];
