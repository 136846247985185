import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';
import { LanguageType } from './interfaces/interfaces';

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
};

const getDefaultLanguage = () => {
  try {
    return (localStorage.getItem('lng') as LanguageType) || process.env.REACT_APP_DEFAULT_LANGUAGE_CODE || 'de';
  } catch (error) {
    return process.env.REACT_APP_DEFAULT_LANGUAGE_CODE || 'de';
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: getDefaultLanguage(),
  interpolation: {
    escapeValue: false,
  },
});

export function translate(key: string, options?: any): string {
  return i18n.t(key, options);
}

export default i18n;
