import { ChatMode, IChatHistory } from '@app/domain/ChatHistoryModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../store';
import { createChatHistory, readChatHistoryOfUser } from '@app/api/chatHistory.api';
import { setSelectedChatId } from './settingsSlice';

export type TriggerActionId = 'RESET_MESSAGES' | 'SHOW_LLM_SETTINGS_MODAL' | null;

export interface ChatBoxState {
  triggerActionId: TriggerActionId;
  editMessageId: string | null;
  loadedChatHistory: IChatHistory[];
}

const initialState: ChatBoxState = {
  triggerActionId: null,
  editMessageId: null,
  loadedChatHistory: [],
};

export const chatBoxSlice = createSlice({
  name: 'chatBoxSlice',
  initialState,
  reducers: {
    setTriggerActionId: (state, { payload }: PayloadAction<{ value: TriggerActionId }>) => {
      state.triggerActionId = payload.value;
    },
    setLoadedChatHistory: (state, { payload }: PayloadAction<{ value: IChatHistory[] }>) => {
      state.loadedChatHistory = payload.value;
    },
    setEditMessageId: (state, { payload }: PayloadAction<{ value: string | null }>) => {
      state.editMessageId = payload.value;
    },
  },
});

export const onCreateNewChatHistory =
  (title: string, chatMode: ChatMode) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const userId = getState().user.user?._id;
      if (userId) {
        const loadedChatHistory = getState().chatBox.loadedChatHistory;
        const numberNewConversationTitles = loadedChatHistory.filter(
          (item) => item.chatMode == chatMode && item.title?.includes(title),
        ).length;
        const newChatHistory: IChatHistory = {
          chatMode,
          userId: userId,
          title: numberNewConversationTitles ? `${title} (${numberNewConversationTitles + 1})` : title,
          messages: [],
        };
        const id = await createChatHistory(newChatHistory);
        newChatHistory._id = id;
        dispatch(setSelectedChatId({ value: id }));
        dispatch(setLoadedChatHistory({ value: [...loadedChatHistory, newChatHistory] }));
      }
    } catch (error) {
      console.log('****** onCreateNewChatHistory error: ', error);
    }
  };

export const loadChatHistory = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const result = await readChatHistoryOfUser(getState().user.user?._id ?? '', 'CHAT_GPT');
    dispatch(setLoadedChatHistory({ value: result || [] }));
  } catch (error) {
    console.log('****** loadChatHistory error: ', error);
  }
};

export const { setTriggerActionId, setLoadedChatHistory, setEditMessageId } = chatBoxSlice.actions;

export default chatBoxSlice.reducer;
