/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import moment from 'moment';
import 'moment/locale/de';
import { Form } from '@quillforms/renderer-core';
import '@quillforms/renderer-core/build-style/style.css';
import { CompanyGoalOptions, CustomFormMessages } from './SurveyData';
import { useNavigate } from 'react-router-dom';
import { FirstStepAnswers } from './SurveyTypes';
//@ts-ignore
import { registerCoreBlocks } from '@quillforms/react-renderer-utils';

registerCoreBlocks();
moment.locale('de');

const AiSurvey: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <Form
        formId={101}
        applyLogic
        formObj={{
          messages: CustomFormMessages,
          blocks: [
            {
              name: 'welcome-screen',
              id: 'jg1401r',
              attributes: {
                label: 'Willkommen zu dem KI Digitalisierungs Berater für Unternehmen',
                description:
                  'Erhalten Sie auf Basis von GPT4 eine individuelle Beratung für das Erreichen Ihrer Unternehmensziele.',
                attachment: {
                  type: 'image',
                  url: 'https://quillforms.com/wp-content/uploads/2022/01/4207-ai-1.jpeg',
                },
              },
            },
            {
              name: 'long-text',
              id: 'company_description',
              attributes: {
                classnames: 'first-block',
                required: true,
                label: 'Was macht Ihr Unternehmen?',
                setMaxCharacters: true,
                maxCharacters: 300,
              },
            },
            {
              name: 'short-text',
              id: 'company_targetaudience',
              attributes: {
                required: true,
                label: 'Beschreiben Sie Ihre Zielgruppe',
              },
            },
            {
              name: 'multiple-choice',
              id: 'company_goals',
              attributes: {
                required: true,
                multiple: true,
                verticalAlign: true,
                label: 'Was sind Ihre mittel und langfristige Unternehmensziele?',
                choices: CompanyGoalOptions,
              },
            },
            {
              name: 'long-text',
              id: 'company_big_challenge',
              attributes: {
                required: true,
                label: 'Was ist momentan Ihre größte Herausforderung?',
              },
            },
          ],
        }}
        onSubmit={(data, { completeForm, setIsSubmitting }) => {
          setTimeout(() => {
            setIsSubmitting(false);
            completeForm();
            const dataAny = data as any;
            if (dataAny.answers) {
              const answers: FirstStepAnswers = {
                company_description: dataAny.answers['company_description'].value,
                company_targetaudience: dataAny.answers['company_targetaudience'].value,
                company_big_challenge: dataAny.answers['company_big_challenge'].value,
                company_goals: dataAny.answers['company_goals'].value.map(
                  (value: string) => CompanyGoalOptions.find((option) => option.value === value)?.label,
                ),
              };
              navigate('/survey-ai-generated', { state: answers });
            }
          }, 500);
        }}
        // beforeGoingNext={({ currentBlockId, answers, goToBlock, goNext }) => {
        //   console.log('****** currentBlockId: ', currentBlockId);
        //   if (currentBlockId === 'company_goals') {
        //     console.log('****** answers: ', answers);
        //   } else {
        //     //goToBlock(NextStepsMap[currentBlockId]);
        //     goNext();
        //   }
        // }}
      />
    </div>
  );
};

export default AiSurvey;
