import { sendPostRequest, sendGetRequest, sendDeleteRequest } from '@app/api/http.api';
import { IDepartment } from '@app/domain/DepartmentModel';

export const createDepartment = (data: IDepartment): Promise<string> => {
  return sendPostRequest<IDepartment, string>('/api/v1/department/create', data);
};

export const readDepartment = (id: string): Promise<IDepartment> => {
  return sendGetRequest<IDepartment>(`/api/v1/department/${id}`);
};

export const readAllDepartmentsOfCompany = (companyId: string): Promise<IDepartment[]> => {
  return sendGetRequest<IDepartment[]>(`/api/v1/department/company/${companyId}`);
};

export const updateDepartment = (id: string, updatedDepartment: Partial<IDepartment>): Promise<string> => {
  return sendPostRequest<{ updatedDepartment: Partial<IDepartment> }, string>(`/api/v1/department/${id}`, {
    updatedDepartment,
  });
};

export const deleteDepartment = (id: string): Promise<string> => {
  return sendDeleteRequest<string>(`/api/v1/department/${id}`);
};
