export const GPT_35_TURBO = 'gpt-3.5-turbo';
export const GPT_4 = 'gpt-4';
export const GPT_4_32k = 'gpt-4-32k';
export type GPT_MODEL = 'gpt-3.5-turbo' | 'gpt-4' | 'gpt-4-32k';
export const GPT_MODEL_NAMES = [GPT_35_TURBO, GPT_4];
export const GPT_MODEL_SELECTION = [
  { value: GPT_35_TURBO, label: 'GPT-3.5 Turbo' },
  { value: GPT_4, label: 'GPT-4 Turbo' },
];

export const DEFAULT_MAX_LOOPS_FREE = 10;
export const DEFAULT_MAX_LOOPS_PAID = 10;
export const DEFAULT_MAX_LOOPS_CUSTOM_API_KEY = 10;

export type ApiIntegrationType = 'ms-sql-server' | 'google-drive' | 'notion';

export const API_INTEGRATIONS = { msSqlServer: 'ms-sql-server', googleDrive: 'google-drive', notion: 'notion' };
