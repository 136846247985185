import { UserModel } from '@app/domain/UserModel';
import { generateFullApiTokenData, httpApi } from '@app/api/http.api';

export const updateUser = (userId: string, updateData: Partial<UserModel>): Promise<void> => {
  return new Promise(async (resolve, reject) => {
    try {
      const apiTokens = generateFullApiTokenData('/update');
      const result = await httpApi.post('/api/v1/user/update', {
        userId,
        updateData,
        ...apiTokens,
      });
      if (result.data?.data) return resolve(result.data?.data);
      if (result.data?.error) return reject(result.data?.error);
      reject('updateUser failed');
    } catch (error: unknown) {
      if (error instanceof Error) {
        reject(error.message);
      } else {
        reject(error);
      }
    }
  });
};

export const uploadUserProfilePhoto = (formData: FormData): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.post('/api/v1/user/uploadProfilePhoto', formData);
      if (result.data?.data) return resolve(result.data.data);
      if (result.data?.error) return reject(result.data.error);
      reject('updateUser failed');
    } catch (error: unknown) {
      if (error instanceof Error) {
        reject(error.message);
      } else {
        reject(error);
      }
    }
  });
};

export const deleteUser = (adminUserId: string, userId: string): Promise<string> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.delete('/api/v1/user/deleteById/' + adminUserId + '/' + userId);
      if (result.data?.data) return resolve(result.data.data);
      if (result.data?.error) return reject(result.data.error);
      reject('updateUser failed');
    } catch (error: unknown) {
      if (error instanceof Error) {
        reject(error.message);
      } else {
        reject(error);
      }
    }
  });
};
