import { TechOfferAnalysisResult } from '@app/types/techOfferTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TechOfferState {
  techOfferAnalysisResults: TechOfferAnalysisResult[];
}

const initialState: TechOfferState = {
  techOfferAnalysisResults: [],
};

export const techOfferSlice = createSlice({
  name: 'techOfferSlice',
  initialState,
  reducers: {
    setTechOfferAnalysisResults: (state, { payload }: PayloadAction<{ value: TechOfferAnalysisResult[] }>) => {
      state.techOfferAnalysisResults = payload.value;
    },
    addTechOfferAnalysisResults: (state, { payload }: PayloadAction<{ value: TechOfferAnalysisResult }>) => {
      state.techOfferAnalysisResults.push(payload.value);
    },
  },
});

export const { setTechOfferAnalysisResults, addTechOfferAnalysisResults } = techOfferSlice.actions;

export default techOfferSlice.reducer;
