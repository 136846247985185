import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AppLayoutState {
  appName: string;
  appLogo: string;
}

const initialState: AppLayoutState = {
  appName: process.env.REACT_APP_CUSTOM_APP_NAME || 'Co-Brain',
  appLogo: process.env.REACT_APP_CUSTOM_APP_LOGO || '/icons/co-brain-logo-text-dark.png',
};

export const appLayoutSlice = createSlice({
  name: 'appLayoutSlice',
  initialState,
  reducers: {
    setAppName: (state, { payload }: PayloadAction<{ value: string }>) => {
      state.appName = payload.value;
    },
    setAppLogo: (state, { payload }: PayloadAction<{ value: string }>) => {
      state.appLogo = payload.value;
    },
  },
});

export const { setAppName, setAppLogo } = appLayoutSlice.actions;

export default appLayoutSlice.reducer;
