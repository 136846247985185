import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './ProfileOverlay.styles';
import { DropdownMenu } from '@app/components/header/Header.styles';
import { ThemePicker } from '../../settingsDropdown/settingsOverlay/ThemePicker/ThemePicker';

const showThemePicker = false;

export const ProfileOverlay: React.FC = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <DropdownMenu selectable={false} {...props}>
      <S.MenuItem key={0}>
        <S.Text>
          <Link to={'/profile/personal-info'} state={{ isSettingsMenu: 'true' }}>
            {t('profile.menuTitle')}
          </Link>
        </S.Text>
      </S.MenuItem>
      <S.ItemsDivider />
      <S.ItemsDivider />
      <S.MenuItem key={1}>
        <S.Text>
          <Link to="/logout">{t('header.logout')}</Link>
        </S.Text>
      </S.MenuItem>
      {showThemePicker && (
        <S.ActionItem>
          <ThemePicker />
        </S.ActionItem>
      )}
    </DropdownMenu>
  );
};
