import React from 'react';
import { useResponsive } from 'hooks/useResponsive';
import { useLocation } from 'react-router-dom';
import * as S from './MainSider.styles';
import { SiderLogo } from '../SiderLogo';
import SiderMenu from '../SiderMenu/SiderMenu';
import SettingsMenu from '../SiderMenu/SettingsMenu';
import Overlay from '@app/components/common/Overlay';
import SiderKnowledgeMenu from '../SiderMenu/SiderKnowledgeMenu';
import { ProfileDropdown } from '@app/components/header/components/profileDropdown/ProfileDropdown/ProfileDropdown';
import { Divider } from 'antd';

interface MainSiderProps {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

const MainSider: React.FC<MainSiderProps> = ({ isCollapsed, setCollapsed, ...props }) => {
  const { isDesktop, mobileOnly, tabletOnly } = useResponsive();
  const location = useLocation();

  const toggleSider = () => setCollapsed(!isCollapsed);

  return (
    <>
      <S.Sider
        trigger={null}
        collapsed={!isDesktop && isCollapsed}
        collapsedWidth={0}
        collapsible={false}
        width={280}
        {...props}
      >
        {location.state?.isSettingsMenu ? (
          <SettingsMenu setCollapsed={setCollapsed} />
        ) : (
          <>
            <SiderLogo isSiderCollapsed={isCollapsed} toggleSider={toggleSider} />
            <S.SiderContentWrapper>
              <S.SiderContent>
                <SiderMenu setCollapsed={setCollapsed} />
                <SiderKnowledgeMenu setCollapsed={setCollapsed} />
              </S.SiderContent>
              <S.SiderBottomContent>
                <Divider />
                <ProfileDropdown inSidebar />
              </S.SiderBottomContent>
            </S.SiderContentWrapper>
          </>
        )}
      </S.Sider>
      {(mobileOnly || tabletOnly) && <Overlay onClick={toggleSider} show={!isCollapsed} />}
    </>
  );
};

export default MainSider;
