import React from 'react';
import { Avatar, Row } from 'antd';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import { useAppSelector } from '@app/hooks/reduxHooks';
import * as S from './ProfileDropdown.styles';
import { themeObject } from '@app/styles/themes/themeVariables';

export type ProfileDropdownProps = {
  inSidebar?: boolean;
};

export const ProfileDropdown: React.FC<ProfileDropdownProps> = ({ inSidebar }) => {
  const user = useAppSelector((state) => state.user.user);
  const theme = useAppSelector((state) => state.theme.theme);
  const companyName = useAppSelector((state) => state.company).companyName ?? 'Guest';
  const userName = user?.firstName && user?.lastName ? `${user.firstName} ${user.lastName}` : 'No name set';

  return user ? (
    <Dropdown overlay={<ProfileOverlay />} trigger={['click']}>
      <S.ProfileDropdownHeader
        as={Row}
        gutter={inSidebar ? undefined : [10, 10]}
        align="middle"
        justify={inSidebar ? undefined : 'end'}
        style={{ paddingLeft: inSidebar ? 24 : 0, maxWidth: 350 }}
      >
        <Row gutter={20}>
          <Avatar
            src={user?.profileImage?.smallUrl}
            alt="User"
            shape="circle"
            size={40}
            style={{ backgroundColor: themeObject[theme].avatarBg }}
          >
            {userName ? userName.slice(0, 2).toUpperCase() : ''}
          </Avatar>
          <S.NameContainer>
            <S.UserName>{userName}</S.UserName>
            <S.CompanyName>{companyName}</S.CompanyName>
          </S.NameContainer>
        </Row>
      </S.ProfileDropdownHeader>
    </Dropdown>
  ) : null;
};
