/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/de';
import { Form } from '@quillforms/renderer-core';
import '@quillforms/renderer-core/build-style/style.css';
import { useLocation } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import { FirstStepAnswers } from './SurveyTypes';
import { BackLink, Block, LoadingBlock } from './AiSurvey.styles';
import { MemoizedReactMarkdown } from '@app/components/common/Markdown/MemoizedReactMarkdown';
import rehypeMathjax from 'rehype-mathjax';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import { Divider, Spin } from 'antd';
import { CustomFormMessages } from './SurveyData';
import { FormObj } from '@quillforms/renderer-core/build-types/types';
//@ts-ignore
import { registerCoreBlocks } from '@quillforms/react-renderer-utils';
import { executeGptQuery } from '@app/api/gpt.api';

registerCoreBlocks();
moment.locale('de');

function AiSurveyAutoGenerated() {
  const location = useLocation();

  const [loadingQuestions, setLoadingQuestions] = useState(true);
  const [generatedQuestions, setGeneratedQuestions] = useState<string[]>([]);
  const [showResults, setShowResults] = useState(false);
  const [reportText, setReportText] = useState<string>('');

  const [formBlocks, setFormBlocks] = useState<FormObj>({
    blocks: [],
  });

  useEffect(() => {
    const generateAiQuestions = async () => {
      try {
        const firstStepAnswers = location.state as FirstStepAnswers;

        const data = await executeGptQuery({
          queryText: `
        Handle als ein innovativer Unternehmensberater mit sehr viel Erfahrung in der Beratung von Unternehmen damit diese mit Digitalisierung und KI erfolgreich werden. Du kennst dich bestens mit den neuen Technologien aus und weißt auch, wie man das am besten einsetzen kann um neue Geschäftsmodelle zu entwickeln. Du handelst immer professionell und hast das Ziel dem Unternehmen wirklich zu helfen.
        Ein Unternehmen hat den Fragebogen wie folgt beantwortet:
        “Was macht Ihr Unternehmen?”: ${firstStepAnswers.company_description}
        “Beschreiben Sie Ihre Zielgruppe”: ${firstStepAnswers.company_targetaudience}
        “Was ist momentan Ihre größte Herausforderung?”: ${firstStepAnswers.company_big_challenge}
        “Was sind Ihre mittel und langfristige Unternehmensziele?”: ${firstStepAnswers.company_goals.join(', ')}
        Generiere auf Basis dieser Antworten weitere 5 Fragen damit du dem Unternehmen am besten bei der Erreichung der Ziele und bei der Lösung der Herausforderungen helfen kannst. 
        Achte darauf, dass die Fragen einfach formuliert sind und schnell beantwortet werden können. Wichtig: gib die Fragen ohne Nummerierung und als ein JSON-Array zurück damit es mit JSON.parse in Array umgewandelt werden kann.`,
        });

        const updatedData = data.replace('```json', '').replace('```', '');

        const newQuestions: string[] = JSON.parse(updatedData);

        setGeneratedQuestions(newQuestions);
        setFormBlocks({
          messages: CustomFormMessages,
          blocks: newQuestions.map((question, index) => ({
            name: 'long-text',
            id: 'generated_question_' + index,
            attributes: {
              required: true,
              label: question,
            },
          })),
        });
      } catch (error) {
        console.log('****** error: ', error);
        notificationController.error({
          message: 'Das hat leider nicht geklappt. Bitte wiederhole die Anfrage erneut.',
        });
      } finally {
        setLoadingQuestions(false);
      }
    };

    generateAiQuestions();
  }, [location]);

  const generateReport = async (answers: any) => {
    try {
      setLoadingQuestions(true);
      const firstStepAnswers = location.state as FirstStepAnswers;

      const data = await executeGptQuery({
        queryText: `
      Handle als ein innovativer Unternehmensberater mit sehr viel Erfahrung in der Beratung von Unternehmen damit diese erfolgreicher werden. Du kennst dich bestens mit den neuen Technologien aus und weißt auch, wie man das am besten einsetzen kann um neue Geschäftsmodelle zu entwickeln. Du handelst immer professionell und hast das Ziel dem Unternehmen wirklich zu helfen.
      Ein Unternehmen hat den Fragebogen wie folgt beantwortet:
      "Was macht Ihr Unternehmen?": ${firstStepAnswers.company_description}
      "Beschreiben Sie Ihre Zielgruppe": ${firstStepAnswers.company_targetaudience}
      "Was ist momentan Ihre größte Herausforderung?": ${firstStepAnswers.company_big_challenge}
      "Was sind Ihre mittel und langfristige Unternehmensziele?": ${firstStepAnswers.company_goals.join(', ')}
      ${generatedQuestions.map((question, index) => `"${question}": ${answers['generated_question_' + index].value}\n`)}

      Erstelle auf Basis der Antworten aus dem Fragebogen eine vollständige Strategie-Empfehlung (max. 4000 Zeichen) womit das Unternehmen am besten die Ziele erreichen kann.
      Die Struktur der Strategie-Empfehlung sollte folgende Kapitel enthalten: Executive Summary, Wie erreichen Sie Ihre Ziele?, Wie meistern Sie Ihre Herausforderungen?, Step-by-Step Roadmap
      Die Strategie-Empfehlung soll wirklich Mehrwert liefern und ins Detail gehen. Formuliere die Ergebnisse als Erkentniss und begründe deine Erkentnisse.
      Gib die Strategie-Empfehlung schön formatiert in Markdown zurück.`,
      });

      const reportText: string = data;
      setReportText(reportText);
      setShowResults(true);
    } catch (error) {
      notificationController.error({
        message: 'Das hat leider nicht geklappt. Bitte wiederhole die Anfrage erneut.',
      });
    } finally {
      setLoadingQuestions(false);
    }
  };

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      {loadingQuestions ? (
        <LoadingBlock>
          <Spin tip="Ihre Daten werden von der KI verarbeitet ..." size="large">
            <div className="content" />
          </Spin>
        </LoadingBlock>
      ) : (
        <>
          {showResults ? (
            <Block>
              <BackLink to="/survey">{'Zurück'}</BackLink>
              <Divider />
              <MemoizedReactMarkdown
                className="prose flex-1"
                remarkPlugins={[remarkGfm, remarkMath]}
                rehypePlugins={[rehypeMathjax]}
              >
                {`${reportText}`}
              </MemoizedReactMarkdown>
            </Block>
          ) : (
            <Form
              formId={102}
              applyLogic
              formObj={formBlocks}
              onSubmit={(data, { completeForm, setIsSubmitting }) => {
                setTimeout(() => {
                  setIsSubmitting(false);
                  completeForm();
                  console.log('****** data: ', data);
                  //@ts-ignore
                  generateReport(data.answers);
                }, 500);
              }}
            />
          )}
        </>
      )}
    </div>
  );
}

export default AiSurveyAutoGenerated;
