import { UserModel } from '@app/domain/UserModel';
import { setSessionExpired } from '@app/store/slices/userSlice';
import { RootState, store } from '@app/store/store';

export type UserRelatedApiData = {
  userId: string;
  companyId: string;
};

export const readToken = (): string | null => {
  const rootState = store.getState() as RootState;
  return rootState.user.accessToken;
};

export const readUser = (): UserModel | null => {
  const rootState = store.getState() as RootState;
  return rootState.user.user;
};

export const readUserIdAndCompanyId = (): UserRelatedApiData => {
  const rootState = store.getState() as RootState;
  return { userId: rootState.user.user?._id ?? '', companyId: rootState.company._id ?? '' };
};

export const setGlobalSessionExpired = (): void => {
  const user = readUser();
  user && store.dispatch(setSessionExpired(true));
};

export const readSecurityToken = (): string | null => {
  const rootState = store.getState() as RootState;
  return rootState.user.securityToken;
};
