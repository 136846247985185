import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LegalDraftState {
  aiGeneratedContent: string | null;
}

const initialState: LegalDraftState = {
  aiGeneratedContent: null,
};

export const legalDraftSlice = createSlice({
  name: 'legalDraft',
  initialState,
  reducers: {
    setAiGeneratedContent: (state, { payload }: PayloadAction<{ value: string }>) => {
      state.aiGeneratedContent = payload.value;
    },
  },
});

export const { setAiGeneratedContent } = legalDraftSlice.actions;

export default legalDraftSlice.reducer;
