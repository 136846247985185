import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Col, Row } from 'antd';
import { IconLogout } from '@tabler/icons-react';
import * as S from './SiderMenu.styles';
import { MenuWrapper, HeaderContainer, BackIcon, HeaderTitle, StyledLogoutLink } from './SettingsMenu.styles';
import { SidebarNavigationItem, settingsNavigation } from '../sidebarNavigation';
import { useAppSelector } from '@app/hooks/reduxHooks';

interface SettingsContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

const SettingsMenu: React.FC<SettingsContentProps> = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const currentUser = useAppSelector((state) => state.user.user);
  const { appName } = useAppSelector((state) => state.appLayout);

  const currentMenuItem = settingsNavigation.find(({ url }) => url === location.pathname);
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];

  const openedSubmenu = useRef<SidebarNavigationItem | undefined>();
  const defaultOpenKeys = useRef<string[]>();

  useEffect(() => {
    openedSubmenu.current = settingsNavigation.find(({ children }) =>
      children?.some(({ url }) => url === location.pathname),
    );
    defaultOpenKeys.current = openedSubmenu.current ? [openedSubmenu.current.key] : [];
  }, [location.pathname]);

  //visibleForRole

  return (
    <MenuWrapper>
      <div>
        <HeaderContainer>
          <Row align={'middle'}>
            <Col span={5}>
              <Link to="/">
                <BackIcon />
              </Link>
            </Col>
            <Col span={19}>
              <HeaderTitle>{appName}</HeaderTitle>
              <br />
              <HeaderTitle>Settings</HeaderTitle>
            </Col>
          </Row>
        </HeaderContainer>
        <S.Menu
          mode="inline"
          defaultSelectedKeys={defaultSelectedKeys}
          defaultOpenKeys={defaultOpenKeys.current}
          selectedKeys={defaultSelectedKeys}
          onClick={() => setCollapsed(true)}
          items={settingsNavigation
            .filter((item) => !item.visibleForRole || item.visibleForRole.includes(currentUser?.role ?? 'EMPLOYEE'))
            .map((nav) => ({
              key: nav.key,
              title: t(nav.title),
              label: (
                <Link to={nav.url || ''} state={{ isSettingsMenu: 'true' }}>
                  {t(nav.title)}
                </Link>
              ),
              icon: nav.icon,
            }))}
        />
      </div>
      <StyledLogoutLink to="/logout">
        <IconLogout style={{ verticalAlign: 'top', marginRight: '0.5rem', height: 20 }} />
        {t('header.logout')}
      </StyledLogoutLink>
    </MenuWrapper>
  );
};

export default SettingsMenu;
