import { httpApi, sendGetRequest } from '@app/api/http.api';
import { BuildVersion } from '@app/constants/Build';
import { IVersionCheckResult } from '@app/domain/VersionCheckModel';

export const checkConnectionIsAlive = (): Promise<boolean> => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await httpApi.get(`/alive`);
      if (result.data?.data) return resolve(true);
      resolve(false);
    } catch (error: unknown) {
      if (error instanceof Error) {
        reject(error.message);
      } else {
        reject(error);
      }
    }
  });
};

export const checkVersionIsOk = (): Promise<IVersionCheckResult> => {
  return sendGetRequest<IVersionCheckResult>(`/api/v1/general/clientVersionCheck/${BuildVersion}`);
};
