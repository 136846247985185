import { ChatMode, IChatHistory } from '@app/domain/ChatHistoryModel';
import {
  sendPostRequest,
  sendGetRequest,
  sendDeleteRequest,
  generateFullApiTokenData,
  ApiTokenData,
} from '../api/http.api';

export const createChatHistory = (data: IChatHistory): Promise<string> => {
  return sendPostRequest<IChatHistory, string>('/api/v1/chatHistory/create', data);
};

export const readChatHistory = (userId: string, id: string): Promise<IChatHistory | null> => {
  return sendGetRequest<IChatHistory | null>(`/api/v1/chatHistory/${userId}/${id}`);
};

export const readChatHistoryOfUser = (userId: string, chatMode: ChatMode): Promise<IChatHistory[] | null> => {
  const apiTokens = generateFullApiTokenData('/readByUser');
  const data = {
    userId,
    chatMode,
    ...apiTokens,
  };
  return sendPostRequest<{ userId: string; chatMode: ChatMode } & ApiTokenData, IChatHistory[] | null>(
    `/api/v1/chatHistory/readByUser`,
    data,
  );
};

export const readOrCreateChatHistory = (
  userId: string,
  chatMode: ChatMode,
  areaId?: string,
): Promise<IChatHistory | null> => {
  const apiTokens = generateFullApiTokenData('/readOrCreate');
  return sendPostRequest<{ userId: string; areaId?: string; chatMode: ChatMode } & ApiTokenData, IChatHistory | null>(
    '/api/v1/chatHistory/readOrCreate',
    { userId, areaId, chatMode, ...apiTokens },
  );
};

export const updateChatHistory = (id: string, updatedChatHistory: Partial<IChatHistory>): Promise<boolean> => {
  const apiTokens = generateFullApiTokenData('/update');
  return sendPostRequest<{ updatedChatHistory: Partial<IChatHistory> } & ApiTokenData, boolean>(
    `/api/v1/chatHistory/${id}`,
    {
      updatedChatHistory,
      ...apiTokens,
    },
  );
};

export const resetMessagesInChatHistory = (id: string): Promise<boolean> => {
  const apiTokens = generateFullApiTokenData('/resetMessages');
  return sendPostRequest<{ id: string } & ApiTokenData, boolean>(`/api/v1/chatHistory/resetMessages`, {
    id,
    ...apiTokens,
  });
};

export const deleteMessagesInChatHistory = (id: string, deleteFromIndex: number): Promise<boolean> => {
  const apiTokens = generateFullApiTokenData('/deleteMessages');
  return sendPostRequest<{ id: string; deleteFromIndex: number } & ApiTokenData, boolean>(
    `/api/v1/chatHistory/deleteMessages`,
    {
      id,
      deleteFromIndex,
      ...apiTokens,
    },
  );
};

export const deleteChatHistory = (id: string): Promise<boolean> => {
  return sendDeleteRequest<boolean>(`/api/v1/chatHistory/${id}`);
};
