import { KnowledgeAreaModel } from '@app/domain/KnowledgeAreaModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../store';
import { readAllKnowledgeAreaForUser } from '@app/api/knowledgeArea.api';

export interface KnowledgeAreaState {
  loadedKnowledgeAreas: KnowledgeAreaModel[];
  knowledgeAreaLoadingError: boolean;
}

const initialState: KnowledgeAreaState = {
  loadedKnowledgeAreas: [],
  knowledgeAreaLoadingError: false,
};

export const knowledgeAreaSlice = createSlice({
  name: 'knowledgeAreaSlice',
  initialState,
  reducers: {
    setLoadedKnowledgeAreas: (state, { payload }: PayloadAction<{ value: KnowledgeAreaModel[] }>) => {
      state.loadedKnowledgeAreas = payload.value;
    },
    setKnowledgeAreaLoadingError: (state, { payload }: PayloadAction<{ value: boolean }>) => {
      state.knowledgeAreaLoadingError = payload.value;
    },
  },
});

export const loadKnowledgeAreas = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    dispatch(setKnowledgeAreaLoadingError({ value: false }));
    if (getState().company._id) {
      const knowledgeAreas = await readAllKnowledgeAreaForUser(
        getState().company._id ?? '',
        getState().user.user?._id ?? '',
        false,
      );
      if (knowledgeAreas?.length) {
        dispatch(setLoadedKnowledgeAreas({ value: knowledgeAreas }));
      }
    }
  } catch (error) {
    console.log('****** loadKnowledgeAreas error: ', error);
    dispatch(setKnowledgeAreaLoadingError({ value: true }));
  }
};

export const { setLoadedKnowledgeAreas, setKnowledgeAreaLoadingError } = knowledgeAreaSlice.actions;

export default knowledgeAreaSlice.reducer;
