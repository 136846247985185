import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Space, Row, Col, Checkbox } from 'antd';
import { notificationController } from '@app/controllers/notificationController';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { getCompanyTeamMembers } from '@app/api/company.api';
import { UserModel } from '@app/domain/UserModel';
import { themeObject } from '@app/styles/themes/themeVariables';
import { NormalText } from '../common/BaseTexts/BaseTexts';
import { AccessRight } from '@app/domain/AccessRightsModel';
import { useTranslation } from 'react-i18next';
import { AccessRightTeamMember } from './OverviewAccessRights';
import { UserRowWrapper } from './SearchUsersAccessRights.styles';

type SearchUsersAccessRightsProps = {
  hideCurrentUser?: boolean;
  hideWriteCheckbox?: boolean;
  readAccessLabel?: string;
  writeAccessLabel?: string;
  accessRightTeamMembers: AccessRightTeamMember[];
  setAccessRightTeamMembers: (rights: AccessRightTeamMember[]) => void;
};

const SearchUsersAccessRights: React.FC<SearchUsersAccessRightsProps> = ({
  hideCurrentUser,
  hideWriteCheckbox,
  readAccessLabel,
  writeAccessLabel,
  accessRightTeamMembers,
  setAccessRightTeamMembers,
}) => {
  const { t } = useTranslation();
  const [companyUsers, setCompanyUsers] = useState<UserModel[]>([]);

  const theme = useAppSelector((state) => state.theme.theme);
  const companyState = useAppSelector((state) => state.company);
  const currentUser = useAppSelector((state) => state.user.user);

  useEffect(() => {
    readAllCompanyUsers();
  }, []);

  const readAllCompanyUsers = useCallback(async () => {
    try {
      const companyTeamMembers = await getCompanyTeamMembers(companyState._id ?? '');
      if (currentUser) {
        setCompanyUsers([{ ...currentUser }, ...companyTeamMembers.filter((item) => item._id !== currentUser?._id)]);
      }
    } catch (error) {
      console.log('****** readAccessRights error: ', error);
      if (error instanceof Error) {
        notificationController.error({ message: error.message });
      } else if (typeof error === 'string') {
        notificationController.error({ message: error });
      } else {
        notificationController.error({ message: 'Fehler beim Zugriff auf den Server' });
      }
    }
  }, [companyState]);

  return (
    <>
      <Row justify={'center'} align={'middle'}>
        <Col span={5} flex={'column'}>
          <NormalText centered size="s" semiBold>
            {readAccessLabel ?? 'Has access'}
          </NormalText>
        </Col>
        {!hideWriteCheckbox && (
          <Col span={5}>
            <NormalText centered size="s" semiBold>
              {writeAccessLabel ?? 'Modify data'}
            </NormalText>
          </Col>
        )}
        <Col span={hideWriteCheckbox ? 19 : 14}>
          <div />
        </Col>
      </Row>

      {companyUsers.map((user, index) => {
        const foundAccessRightTeamMember = accessRightTeamMembers.find((item) => item.userId === user._id);
        const teamMemberName = `${user.firstName} ${user.lastName}`;
        const isCurrentUser = currentUser?._id === user._id;

        const onChecked = (accessRight: AccessRight, checked: boolean) => {
          if (!foundAccessRightTeamMember && checked) {
            setAccessRightTeamMembers([
              ...accessRightTeamMembers,
              { accessRight, userId: user._id, teamMemberName: '' },
            ]);
          } else {
            if (checked) {
              const updatedAccessRights = accessRightTeamMembers.filter((item) => item.userId !== user._id);
              setAccessRightTeamMembers([
                ...updatedAccessRights,
                { accessRight, userId: user._id, teamMemberName: '' },
              ]);
            } else {
              if (accessRight === 'READ_ONLY' && foundAccessRightTeamMember?.accessRight === 'WRITE_AND_READ') {
                const updatedAccessRights = accessRightTeamMembers.filter((item) => item.userId !== user._id);
                setAccessRightTeamMembers(updatedAccessRights);
              } else if (
                accessRight === 'WRITE_AND_READ' &&
                foundAccessRightTeamMember?.accessRight === 'WRITE_AND_READ'
              ) {
                const updatedAccessRights = accessRightTeamMembers.filter((item) => item.userId !== user._id);
                setAccessRightTeamMembers([
                  ...updatedAccessRights,
                  { accessRight: 'READ_ONLY', userId: user._id, teamMemberName: '' },
                ]);
              } else {
                const updatedAccessRights = accessRightTeamMembers.filter((item) => item.userId !== user._id);
                setAccessRightTeamMembers(updatedAccessRights);
              }
            }
          }
        };

        return (
          <UserRowWrapper justify={'center'} align={'middle'} key={'search_user_' + index}>
            <Col span={5} flex={'column'}>
              <Row justify={'center'}>
                <Checkbox
                  disabled={hideCurrentUser && isCurrentUser}
                  checked={
                    hideCurrentUser && isCurrentUser
                      ? true
                      : foundAccessRightTeamMember?.accessRight === 'READ_ONLY' ||
                        foundAccessRightTeamMember?.accessRight === 'WRITE_AND_READ'
                  }
                  onChange={(e) => onChecked('READ_ONLY', e.target.checked)}
                />
              </Row>
            </Col>
            {!hideWriteCheckbox && (
              <Col span={5}>
                <Row justify={'center'}>
                  <Checkbox
                    checked={
                      hideCurrentUser && isCurrentUser
                        ? true
                        : foundAccessRightTeamMember?.accessRight === 'WRITE_AND_READ'
                    }
                    disabled={hideCurrentUser && isCurrentUser}
                    onChange={(e) => onChecked('WRITE_AND_READ', e.target.checked)}
                  />
                </Row>
              </Col>
            )}
            <Col span={hideWriteCheckbox ? 19 : 14}>
              <Space>
                <Avatar
                  alt="User"
                  shape="circle"
                  size={'default'}
                  src={user.profileImage?.smallUrl}
                  style={{ backgroundColor: themeObject[theme].avatarBg }}
                >
                  {teamMemberName ? teamMemberName.slice(0, 2).toUpperCase() : ''}
                </Avatar>
                <NormalText semiBold={isCurrentUser}>{isCurrentUser ? t('common.you') : teamMemberName}</NormalText>
              </Space>
            </Col>
          </UserRowWrapper>
        );
      })}
    </>
  );
};

export default SearchUsersAccessRights;
