import React, { ComponentType } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';
import AuthConfirmEmailPage from '@app/pages/AuthConfirmEmailPage';
import TeamMemberInvitationValidationPage from '@app/pages/TeamMemberInvitationValidationPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import AiSurvey from '@app/pages/Survey/AiSurvey';
import AiSurveyAutoGenerated from '@app/pages/Survey/AiSurveyAutoGenerated';
import AiSurveyHR from '@app/pages/SurveyHRDigitalization/AiSurveyHR';
import AiSurveyHRAutoGenerated from '@app/pages/SurveyHRDigitalization/AiSurveyHRAutoGenerated';

/*
 * A function to retry loading a chunk to avoid chunk load error for out of date code
 */
const lazyRetry = (componentImport: any): Promise<{ default: ComponentType<any> }> => {
  return new Promise((resolve, reject) => {
    const hasAlreadyRefreshed = JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') || 'false');
    // try to import the component
    componentImport()
      .then((component: any) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
        resolve(component);
      })
      .catch((error: any) => {
        if (!hasAlreadyRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
          return window.location.reload(); // refresh the page
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });
};

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => lazyRetry(() => import('@app/components/layouts/AuthLayout/AuthLayout')));

const ServerErrorPage = React.lazy(() => lazyRetry(() => import('@app/pages/ServerErrorPage')));
const Error404Page = React.lazy(() => lazyRetry(() => import('@app/pages/Error404Page')));
const PersonalInfoPage = React.lazy(() => lazyRetry(() => import('@app/pages/PersonalInfoPage')));
const SecuritySettingsPage = React.lazy(() => lazyRetry(() => import('@app/pages/SecuritySettingsPage')));
const RightsManagementPage = React.lazy(() =>
  lazyRetry(() => import('@app/pages/RightsManagement/RightsManagementPage')),
);
const NotificationsPage = React.lazy(() => lazyRetry(() => import('@app/pages/Notifications/NotificationsPage')));
const DataroomOverviewPage = React.lazy(() => lazyRetry(() => import('@app/pages/Dataroom/DataroomOverviewPage')));
const SourceUpdateMonitoringOverviewPage = React.lazy(() =>
  lazyRetry(() => import('@app/pages/SourceUpdateMonitoring/SourceUpdateMonitoringOverviewPage')),
);
const DataroomAreaPage = React.lazy(() => lazyRetry(() => import('@app/pages/Dataroom/DataroomAreaPage')));
const SourceUpdateMonitoringPage = React.lazy(() =>
  lazyRetry(() => import('@app/pages/SourceUpdateMonitoring/SourceUpdateMonitoringPage')),
);
const CompanyOverviewPage = React.lazy(() => lazyRetry(() => import('@app/pages/CompanyOverview/CompanyOverviewPage')));
const UsageAnalyticsPage = React.lazy(() => lazyRetry(() => import('@app/pages/UsageAnalytics/UsageAnalyticsPage')));
const PlatformSettingsPage = React.lazy(() =>
  lazyRetry(() => import('@app/pages/PlatformSettings/PlatformSettingsPage')),
);
const GPTAgentsPage = React.lazy(() => lazyRetry(() => import('@app/pages/GPTAgents/GPTAgents')));
const GPTSearchPage = React.lazy(() => lazyRetry(() => import('@app/pages/GPTSearch/GPTSearch')));
const GPTTemplatesPage = React.lazy(() => lazyRetry(() => import('@app/pages/GPTTemplates/GPTTemplates')));
const GPTTemplateDetailsPage = React.lazy(() => lazyRetry(() => import('@app/pages/GPTTemplates/GPTTemplateDetails')));
const GPTTemplateCustomPage = React.lazy(() => lazyRetry(() => import('@app/pages/GPTTemplates/GPTTemplateCustom')));
const GPTKnowledgebaseSearchPage = React.lazy(() =>
  lazyRetry(() => import('@app/pages/GPTKnowledgebaseSearch/GPTKnowledgebaseSearch')),
);
const DraftDocumentPage = React.lazy(() => lazyRetry(() => import('@app/pages/Legal/DraftDocument')));
const AnalyzeDocumentPage = React.lazy(() => lazyRetry(() => import('@app/pages/Legal/AnalyzeDocument')));
const AnalyzeCaveatDocumentPage = React.lazy(() =>
  lazyRetry(() => import('@app/pages/Engineering/AnalyzeCaveatDocument')),
);
const AnalyzeTechOfferDocumentPage = React.lazy(() =>
  lazyRetry(() => import('@app/pages/Engineering/AnalyzeTechOfferDocument')),
);
const GPTAgentSearchPage = React.lazy(() => lazyRetry(() => import('@app/pages/GPTAgentSearch/GPTAgentSearch')));
const Logout = React.lazy(() => lazyRetry(() => import('./Logout')));

export const ROOT_PATH = '/';
export const NOTIFICATION_PATH = '/notifications';

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// Profile
const PersonalInfo = withLoading(PersonalInfoPage);
const SecuritySettings = withLoading(SecuritySettingsPage);
const RightsManagement = withLoading(RightsManagementPage);
const Notifications = withLoading(NotificationsPage);
const DataroomOverview = withLoading(DataroomOverviewPage);
const SourceUpdateMonitoringOverview = withLoading(SourceUpdateMonitoringOverviewPage);
const DataroomArea = withLoading(DataroomAreaPage);
const SourceUpdateMonitoring = withLoading(SourceUpdateMonitoringPage);
const CompanyOverview = withLoading(CompanyOverviewPage);
const UsageAnalytics = withLoading(UsageAnalyticsPage);
const PlatformSettings = withLoading(PlatformSettingsPage);
const GPTAgents = withLoading(GPTAgentsPage);
const GPTSearch = withLoading(GPTSearchPage);
const GPTTemplates = withLoading(GPTTemplatesPage);
const GPTTemplateDetails = withLoading(GPTTemplateDetailsPage);
const GPTTemplateCustom = withLoading(GPTTemplateCustomPage);
const GPTKnowledgebaseSearch = withLoading(GPTKnowledgebaseSearchPage);
const DraftDocument = withLoading(DraftDocumentPage);
const AnalyzeDocument = withLoading(AnalyzeDocumentPage);
const AnalyzeCaveatDocument = withLoading(AnalyzeCaveatDocumentPage);
const AnalyzeTechOfferDocument = withLoading(AnalyzeTechOfferDocumentPage);
const GPTAgentSearch = withLoading(GPTAgentSearchPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROOT_PATH} element={protectedLayout}>
          <Route index element={<GPTSearch />} />
          <Route path="knowledge/:id" element={<GPTKnowledgebaseSearch />} />
          <Route path="survey" element={<AiSurvey />} />
          <Route path="survey-ai-generated" element={<AiSurveyAutoGenerated />} />
          <Route path="survey-hr" element={<AiSurveyHR />} />
          <Route path="survey-ai-hr-generated" element={<AiSurveyHRAutoGenerated />} />
          <Route path={NOTIFICATION_PATH} element={<Notifications />} />
          <Route path="server-error" element={<ServerError />} />
          <Route path="404" element={<Error404 />} />
          <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<PersonalInfo />} />
            <Route path="security-settings" element={<SecuritySettings />} />
            <Route path="notifications" element={<Notifications />} />
          </Route>
          <Route path="gptsearch" element={<GPTSearch />} />
          <Route path="gptagents" element={<GPTAgents />} />
          <Route path="superdos" element={<GPTTemplates />} />
          <Route path="legaldraft" element={<DraftDocument />} />
          <Route path="contractanalyze" element={<AnalyzeDocument />} />
          <Route path="caveatanalyze" element={<AnalyzeCaveatDocument />} />
          <Route path="techofferdocumentanalyze" element={<AnalyzeTechOfferDocument />} />
          <Route path="superdo-details/:id" element={<GPTTemplateDetails />} />
          <Route path="quick-document-review" element={<GPTTemplateCustom />} />
          <Route path="gptagentsearch" element={<GPTAgentSearch />} />
          <Route path="dataroom" element={<DataroomOverview />} />
          <Route path="source-monitoring" element={<SourceUpdateMonitoringOverview />} />
          <Route path="dataroom-selected" element={<DataroomArea />} />
          <Route path="source-monitoring-selected/:id" element={<SourceUpdateMonitoring />} />
          <Route path="company-overview" element={<CompanyOverview />} />
          <Route path="usage-analytics" element={<UsageAnalytics />} />
          <Route path="platform-settings" element={<PlatformSettings />} />
          <Route path="rights-management" element={<RightsManagement />} />
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="secure-sign-up" element={<SignUpPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
          <Route path="team-invitation" element={<TeamMemberInvitationValidationPage />} />
          <Route path="email-verify" element={<AuthConfirmEmailPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
