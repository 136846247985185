import styled from 'styled-components';
import { Row, Typography } from 'antd';
import { Link } from 'react-router-dom';

export const Block = styled.div`
  background-color: #fff;
  padding: 40px;
  margin: 20px auto;
  max-width: 900px;
  border-radius: 8px;
  margin-bottom: 40px;
`;

export const LoadingBlock = styled(Block)`
  padding: 200px;
`;

export const BackLink = styled(Link)`
  padding: 14px;
  background-color: #def1fb;
  border-radius: 8px;
  margin-bottom: 20px;
`;

export const HeadlineText = styled(Typography.Text)`
  font-weight: 600;
  font-size: 1rem;
`;

export const BaseText = styled(Typography.Text)`
  font-weight: 400;
  font-size: 1rem;
`;

export const HeaderRow = styled(Row)`
  margin-bottom: 16px;
`;
