import { createAction, createAsyncThunk, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { UserModel } from '@app/domain/UserModel';
import { AppDispatch, RootState } from '../store';
import { updateUser, uploadUserProfilePhoto } from '@app/api/user.api';
import { readAccessRightsByUser } from '@app/api/accessRights.api';
import { IUserDetailedAccessRights } from '@app/domain/AccessRightsModel';

export interface UserState {
  user: UserModel | null;
  isAdmin: boolean;
  isManager: boolean;
  accessToken: string | null;
  securityToken: string | null;
  userRights: IUserDetailedAccessRights | null;
  sessionExpired?: boolean;
}

const initialState: UserState = {
  user: null,
  isAdmin: false,
  isManager: false,
  accessToken: null,
  securityToken: null,
  userRights: null,
  sessionExpired: false,
};

export const setUser = createAction<PrepareAction<UserModel>>('user/setUser', (newUser) => {
  return {
    payload: newUser,
  };
});

export const setAccessToken = createAction<PrepareAction<string>>('user/setAccessToken', (accessToken) => {
  return {
    payload: accessToken,
  };
});

export const setSecurityToken = createAction<PrepareAction<string>>('user/setSecurityToken', (securityToken) => {
  return {
    payload: securityToken,
  };
});

export const setSessionExpired = createAction<PrepareAction<boolean>>('user/setSessionExpired', (sessionExpired) => {
  return {
    payload: sessionExpired,
  };
});

export const setUserRights = createAction<PrepareAction<IUserDetailedAccessRights | null>>(
  'user/setUserRights',
  (userRights) => {
    return {
      payload: userRights,
    };
  },
);

export const updateUserData = createAsyncThunk(
  'user/updateUserData',
  async ({ userData }: { userData: Partial<UserModel> }, { dispatch, getState }) => {
    const user = (getState() as RootState).user.user;
    if (user) {
      updateUser(user._id, userData).then(() => {
        dispatch(setUser({ ...user, ...userData }));
      });
    }
  },
);

export const uploadProfilePhoto = createAsyncThunk(
  'user/uploadProfilePhoto',
  async (formData: FormData, { dispatch, getState }) => {
    const user = (getState() as RootState).user.user;
    if (user) {
      const photoUrl = await uploadUserProfilePhoto(formData);
      if (photoUrl) {
        dispatch(setUser({ ...user, profileImage: { bigUrl: photoUrl, smallUrl: photoUrl } }));
      }
    }
  },
);

export const loadUserRights = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const rights = await readAccessRightsByUser(getState().company._id ?? '', getState().user.user?._id ?? '-');
    if (rights) {
      dispatch(setUserRights(rights));
    }
  } catch (error) {
    console.log('****** loadUserRights error: ', error);
  }
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUser, (state, action) => {
      state.user = action.payload;
      state.isAdmin = action.payload?.role === 'ADMIN';
      state.isManager = action.payload?.role === 'MANAGER';
    });
    builder.addCase(setAccessToken, (state, action) => {
      state.accessToken = action.payload;
    });
    builder.addCase(setSecurityToken, (state, action) => {
      state.securityToken = action.payload;
    });
    builder.addCase(setSessionExpired, (state, action) => {
      state.sessionExpired = action.payload;
    });
    builder.addCase(setUserRights, (state, action) => {
      state.userRights = action.payload;
    });
  },
});

export default userSlice.reducer;
