import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as S from './SiderMenu.styles';
import { sidebarNavigation } from '../sidebarNavigation';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { NormalText } from '@app/components/common/BaseTexts/BaseTexts';
import { Button, Col, Divider, Row, Tooltip } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { ModalCreateNewDataroom } from '@app/components/dataroom/ModalCreateNewDataroom';

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

const SiderKnowledgeMenu: React.FC<SiderContentProps> = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigation = useNavigate();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const { loadedKnowledgeAreas } = useAppSelector((state) => state.knowledgeArea);

  const currentMenuItem = loadedKnowledgeAreas.find(({ _id }) => location.pathname.includes(_id ?? ''));
  const defaultSelectedKeys = currentMenuItem && currentMenuItem._id ? [currentMenuItem._id] : [];

  const openedSubmenu = sidebarNavigation.find(({ children }) =>
    children?.some(({ url }) => url === location.pathname),
  );
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];

  return (
    <>
      <Divider />
      <Row justify={'space-between'} align={'middle'} style={{ paddingLeft: 20, display: 'flex' }}>
        <Col>
          <NormalText size="m" bold colorType="primary">
            {t('knowledgeAreas')}
          </NormalText>
        </Col>
        <Col>
          <Button type="text" onClick={() => setIsModalVisible(true)}>
            <Tooltip title="Create new" placement={'right'}>
              <PlusCircleOutlined />
            </Tooltip>
          </Button>
        </Col>
      </Row>
      <S.Menu
        mode="inline"
        defaultSelectedKeys={defaultSelectedKeys}
        defaultOpenKeys={defaultOpenKeys}
        selectedKeys={defaultSelectedKeys}
        onClick={() => setCollapsed(true)}
        items={loadedKnowledgeAreas.map((nav, index) => {
          return {
            key: nav._id ?? 'knowBaseIndex_' + index,
            title: t(nav.title),
            label: <Link to={`/knowledge/${nav._id}`}>{`# ${t(nav.title)}`}</Link>,
            children: undefined,
          };
        })}
      />
      <ModalCreateNewDataroom
        showCreateNewDataRoomModal={isModalVisible}
        onCloseModal={(createdKnowledeItemId?: string) => {
          setIsModalVisible(false);
          if (createdKnowledeItemId) {
            navigation(`/knowledge/${createdKnowledeItemId}`);
          }
        }}
      />
    </>
  );
};

export default SiderKnowledgeMenu;
