import { GPT_4, GPT_MODEL } from '@app/utils/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SettingsState {
  gptChatBoxLLM: GPT_MODEL;
  internetSearchActive: boolean;
  turboSpeechModelActive: boolean;
  waitSpeechTextActive: boolean;
  contextSizeVoiceAssistant: 'small' | 'normal' | 'large';
  selectedChatId: string | null;
  lastSelectedKnowledgeAreaId: string | null;
}

const initialState: SettingsState = {
  gptChatBoxLLM: GPT_4,
  internetSearchActive: false,
  turboSpeechModelActive: false,
  waitSpeechTextActive: false,
  contextSizeVoiceAssistant: 'small',
  selectedChatId: null,
  lastSelectedKnowledgeAreaId: null,
};

export const settingsSlice = createSlice({
  name: 'settingsSlice',
  initialState,
  reducers: {
    setGptChatBoxLLM: (state, { payload }: PayloadAction<{ value: GPT_MODEL }>) => {
      state.gptChatBoxLLM = payload.value;
    },
    setInternetSearchActive: (state, { payload }: PayloadAction<{ value: boolean }>) => {
      state.internetSearchActive = payload.value;
    },
    setTurboSpeechModelActive: (state, { payload }: PayloadAction<{ value: boolean }>) => {
      state.turboSpeechModelActive = payload.value;
    },
    setWaitSpeechTextActive: (state, { payload }: PayloadAction<{ value: boolean }>) => {
      state.waitSpeechTextActive = payload.value;
    },
    setContextSizeVoiceAssistant: (state, { payload }: PayloadAction<{ value: 'small' | 'normal' | 'large' }>) => {
      state.contextSizeVoiceAssistant = payload.value;
    },
    setLastSelectedKnowledgeAreaId: (state, { payload }: PayloadAction<{ value: string | null }>) => {
      state.lastSelectedKnowledgeAreaId = payload.value;
    },
    setSelectedChatId: (state, { payload }: PayloadAction<{ value: string | null }>) => {
      state.selectedChatId = payload.value;
    },
    resetSettingsState: (state) => {
      state.internetSearchActive = initialState.internetSearchActive;
      state.selectedChatId = initialState.selectedChatId;
      state.lastSelectedKnowledgeAreaId = initialState.lastSelectedKnowledgeAreaId;
    },
  },
});

export const {
  setGptChatBoxLLM,
  setLastSelectedKnowledgeAreaId,
  setInternetSearchActive,
  setTurboSpeechModelActive,
  setWaitSpeechTextActive,
  setContextSizeVoiceAssistant,
  setSelectedChatId,
  resetSettingsState,
} = settingsSlice.actions;

export default settingsSlice.reducer;
