import { FormMessages } from '@quillforms/types/src/types/form-messages';

const CompanyGoalOptions = [
  {
    label:
      'Wachstum & Differenzierung: Entwicklung neuer Geschäftsmodelle, Produkte oder Dienstleistungen, um Wettbewerbsvorteile zu erlangen und auf sich ändernde Marktbedingungen zu reagieren.',
    value: 'grow',
  },
  {
    label:
      'Kundenorientierung: Verbesserung der Kundenbindung und Steigerung der Kundenzufriedenheit durch den Einsatz digitaler Technologien und Datenanalyse.',
    value: 'improve_customer_experience',
  },
  {
    label: 'Operative Exzellenz: Optimierung interner Abläufe zur Effizienzsteigerung und Kostensenkung.',
    value: 'more_effieciency',
  },
  {
    label: 'Anpassungsfähigkeit: Schnelle Reaktion auf Marktveränderungen und technologische Entwicklungen.',
    value: 'agility',
  },
  {
    label:
      'Organisationskultur: Schaffung einer offenen, kollaborativen Kultur, die Innovation und Talententwicklung fördert.',
    value: 'company_culture',
  },
  {
    label:
      'Datenkompetenz: Nutzung von Datenanalyse, KI und maschinellem Lernen, um fundierte Geschäftsentscheidungen zu treffen und Prognosen über zukünftige Entwicklungen abzuleiten.',
    value: 'data_know_how',
  },
  {
    label:
      'Cybersicherheit und Datenschutz: Gewährleistung der Sicherheit von Unternehmens- und Kundendaten sowie Einhaltung von Datenschutzbestimmungen und Sicherheitsstandards.',
    value: 'cybersecurity',
  },
  {
    label:
      'Nachhaltigkeit: Förderung von ökologischer und sozialer Nachhaltigkeit sowie ethisch verantwortungsvoller Praktiken.',
    value: 'sustainbility',
  },
];

const CustomFormMessages: Partial<FormMessages> = {
  'label.button.ok': 'Weiter',
  'label.hintText.enter': 'Drücken Sie <strong>Enter ↵</strong>',
  'block.shortText.placeholder': 'Geben Sie Ihre Antwort hier ein',
  'block.longText.placeholder': 'Geben Sie Ihre Antwort hier ein',
  'block.longText.hint': '<strong>Shift ⇧ + Enter ↵</strong> für Zeilenumbruch',
  'block.defaultThankYouScreen.label':
    'Vielen Dank für das Ausfüllen dieses Formulars.\n\nWir werden uns bald mit Ihnen in Verbindung setzen.',
  'label.submitBtn': 'Senden',
};

export { CompanyGoalOptions, CustomFormMessages };
