import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../store';
import { createCompany, readCompany, updateCompany } from '@app/api/company.api';
import { CompanyModel } from '@app/domain/CompanyModel';
import { updateUserData } from './userSlice';
import { setAppLogo, setAppName } from './appLayoutSlice';

const initialState: CompanyModel = {
  companyName: undefined,
  companyDescription: undefined,
  companyWhy: undefined,
  domainSpecificTerms: undefined,
  _id: undefined,
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanyName: (state, { payload }: PayloadAction<{ value: string }>) => {
      state.companyName = payload.value;
    },
    setCompanyDescription: (state, { payload }: PayloadAction<{ value: string }>) => {
      state.companyDescription = payload.value;
    },
    setCompanyWhy: (state, { payload }: PayloadAction<{ value: string }>) => {
      state.companyWhy = payload.value;
    },
    setDomainSpecificTerms: (state, { payload }: PayloadAction<{ value: string }>) => {
      state.domainSpecificTerms = payload.value;
    },
    setCompanyId: (state, { payload }: PayloadAction<{ value: string }>) => {
      state._id = payload.value;
    },
  },
});

export const loadCompany = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  try {
    const company = await readCompany(getState().user.user?.belongsToCompanyId ?? '-');

    if (company) {
      dispatch(setCompanyName({ value: company.companyName ?? '' }));
      dispatch(setCompanyDescription({ value: company.companyDescription ?? '' }));
      dispatch(setCompanyWhy({ value: company.companyWhy ?? '' }));
      dispatch(setCompanyId({ value: company._id ?? '' }));
      dispatch(setDomainSpecificTerms({ value: company.domainSpecificTerms ?? '' }));
      // appLayoutSlice
      company.globalAppName && dispatch(setAppName({ value: company.globalAppName }));
      company.globalAppLogoUrl && dispatch(setAppLogo({ value: company.globalAppLogoUrl }));
    }
  } catch (error) {
    console.log('****** loadCompany error: ', error);
  }
};

export const createCompanyData =
  ({
    companyName,
    companyDescription,
    companyWhy,
  }: {
    companyName: string;
    companyDescription: string;
    companyWhy: string;
  }) =>
  async (dispatch: AppDispatch) => {
    try {
      const companyId = await createCompany({
        companyName,
        companyDescription,
        companyWhy,
      });

      if (companyId) {
        dispatch(setCompanyName({ value: companyName }));
        dispatch(setCompanyDescription({ value: companyDescription }));
        dispatch(setCompanyWhy({ value: companyWhy }));
        dispatch(setCompanyId({ value: companyId }));
        dispatch(updateUserData({ userData: { belongsToCompanyId: companyId } }));
      }
    } catch (error) {
      console.log('****** createCompanyData error: ', error);
      throw error;
    }
  };

export const updateCompanyDomainSpecificTerms =
  ({ domainSpecificTerms }: { domainSpecificTerms: string }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const result = await updateCompany(getState().user.user?.belongsToCompanyId ?? '-', {
        domainSpecificTerms,
      });

      if (result) {
        dispatch(setDomainSpecificTerms({ value: domainSpecificTerms }));
      }
    } catch (error) {
      console.log('****** updatesetCompanyDomainSpecificTerms error: ', error);
      throw error;
    }
  };

export const updateCompanyData =
  (data: Partial<CompanyModel>) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { companyName, companyDescription, companyWhy, globalAppName } = data;

      const result = await updateCompany(getState().user.user?.belongsToCompanyId ?? '-', data);

      if (result) {
        companyName && dispatch(setCompanyName({ value: companyName }));
        dispatch(setCompanyDescription({ value: companyDescription ?? '' }));
        dispatch(setCompanyWhy({ value: companyWhy ?? '' }));
        // appLayoutSlice
        globalAppName && dispatch(setAppName({ value: globalAppName ?? '' }));
      }
    } catch (error) {
      console.log('****** updateCompanyData error: ', error);
      throw error;
    }
  };

export const { setCompanyName, setCompanyDescription, setCompanyWhy, setCompanyId, setDomainSpecificTerms } =
  companySlice.actions;

export default companySlice.reducer;
